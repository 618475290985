import React, { useState, useEffect } from 'react';
import logo from './suplex-asia-logo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import backgroundImage from './teal background.jpg';
import { database, ref, get, child, set } from './firebase';
import { v4 as uuidv4 } from 'uuid';
//import { getFunctions, httpsCallable } from 'firebase/functions';

function Registration() {
    const navigate = useNavigate();
    const location = useLocation();
    const [showDropdown, setShowDropdown] = useState(false);
    const [hoverIndex, setHoverIndex] = useState(null);
   // const [modalData, setModalData] = useState({ placementTop: '', password: '' });
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        extraTel: '',
        address: '',
        nic: '',
        teamManager: '',
        district: '',
        city: '',
        deliveryCode: '',
        presenter: '',
        placementTop: '',
        teamLeader: '',
        bank: '',
        branch: '',
        accountType: '',
        accountHolderName: '',
        accountNumber: '',
        unit: ''
    });
    const [formErrors, setFormErrors] = useState({});
    const [placementTopError, setPlacementTopError] = useState('');
    const [NICError, setNicError] = useState('');
    const [isAccountInfoLocked, setIsAccountInfoLocked] = useState(false);
    const [placeholders, setPlaceholders] = useState({});
    const [teamLeaders, setTeamLeaders] = useState([]);
    const [placementTop, setPlacementTop] =useState([]);

   // Initialize formData from location.state if available
   useEffect(() => {
    const fetchUserData = async (placementTop) => {
        try {
            const snapshot = await get(child(ref(database), 'users'));
            if (snapshot.exists()) {
                const users = snapshot.val();
                const user = Object.values(users).find(user => user.placementTop === placementTop);
                if (user) {
                    setFormData(prevData => ({
                        ...prevData,
                        teamManager: `${user.name} (${user.placementTop})`, // Auto-fill Team Manager
                        placementTop: user.placementTop,
                        name: user.name,
                        email: user.email,
                        phone: user.phone,
                        extraTel: user.extraTel,
                        address: user.address,
                        nic: user.nic,
                        district: user.district,
                        city: user.city,
                        deliveryCode: user.deliveryCode,
                        presenter: user.presenter,
                        teamLeader: user.teamLeader,
                        bank: user.bank,
                        branch: user.branch,
                        accountType: user.accountType,
                        accountHolderName: user.accountHolderName,
                        accountNumber: user.accountNumber,
                        unit: user.unit
                    }));
                    setIsAccountInfoLocked(false);
                    setPlacementTopError('');
                    setNicError('');
                    fetchTeamLeaders(user.teamManager); // Fetch team leaders based on team manager
                    fetchAllPlacementTop(user.placementTop);
                    fetchAllPlacementTop(user.name);
                    fetchUserData(placementTop.placementTop);
        
                }
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

        if (location.state && location.state.placementTop) {
            fetchUserData(location.state.placementTop);
        }
    }, [location.state]);

    const fetchTeamLeaders = async (teamManagerUsername) => {
        try {
            const snapshot = await get(child(ref(database), 'users'));
            if (snapshot.exists()) {
                const users = snapshot.val();
                const leaders = Object.values(users).filter(user => user.teamManager === teamManagerUsername);
                setTeamLeaders(leaders);
            }
        } catch (error) {
            console.error("Error fetching team leaders:", error);
        }
    };
    const fetchAllPlacementTop = async () => {
        try {
            const snapshot = await get(child(ref(database), 'users'));
            if (snapshot.exists()) {
                const users = snapshot.val();
                // Extract all unique placementTop values
                const placementTops = Object.values(users).map(user => user.placementTop).filter((value, index, self) => self.indexOf(value) === index);
                setPlacementTop(placementTops); // Update state with unique placementTop values
            }
        } catch (error) {
            console.error("Error fetching placement tops:", error);
        }
    };
    
    
    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleMouseEnter = (index) => {
        setHoverIndex(index);
    };

    const handleMouseLeave = () => {
        setHoverIndex(null);
    };

    const dropdownItems = ["Change Password", "Update Info", "Invoice Report"];
    const districts = ["Colombo", "Gampaha", "Kalutara", "Kandy", "Matale", "Nuwara Eliya", "Galle", "Matara", "Hambantota", "Jaffna", "Kilinochchi", "Mannar", "Vavuniya", "Mullaitivu", "Batticaloa", "Ampara", "Trincomalee", "Kurunegala", "Puttalam", "Anuradhapura", "Polonnaruwa", "Badulla", "Moneragala", "Ratnapura", "Kegalle"];
    const accountTypes = ["Savings", "Current"];
    const banks = [
        "Bank of Ceylon",
        "People's Bank",
        "Commercial Bank of Ceylon",
        "Hatton National Bank",
        "Sri Lanka Savings Bank",
        "National Development Bank",
        "Union Bank of Colombo",
        "Sampath Bank",
        "DFCC Bank",
        "BOC Holdings",
        "Cal Bank",
        "Pan Asia Bank",
        "Amana Bank",
        "Bank of East Asia",
        "HSBC Sri Lanka",
        "Standard Chartered Bank Sri Lanka",
        "Citi Bank Sri Lanka",
        "Nations Trust Bank",
        "Sri Lankan Insurance Corporation",
        "Sri Lanka Post Bank"
      ];
      
    const units = ["Unit 1", "Unit 2", "Unit 3"];
    const deliveryCode = [
        "12", "455", "A", "AA", "AN", "AS", "B", "BAA", "BCC", "BE", "BN", "BT", "CD", "CP",
        "CS", "CT", "D", "E", "EA", "EE", "EN", "ES", "EV", "EY", "J", "JB", "JC", "JJ",
        "JW", "K", "KA", "KC", "KG", "KJ", "KL", "KLA", "KLR", "KM", "KP", "KS", "NEW K",
        "R", "S", "SA", "SBM", "SK", "SL", "SM", "SPS", "T", "TA", "TC", "TU", "TW", "U",
        "UL", "US","UW", "W","WB","WC" ];
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (name === 'teamManager') {
            fetchTeamLeaders(value);
        }
    };
    const validateForm = () => {
        const errors = {};
        if (!formData.name) errors.name = "Name is required.";
        if (!formData.email) errors.email = "Email is required.";
        if (!formData.phone || formData.phone.length !== 10) errors.phone = "Phone number should be 10 characters long.";
        if (!formData.address) errors.address = "Address is required.";
        if (!formData.nic || (formData.nic.length !== 10 && formData.nic.length !== 12)) errors.nic = "NIC should be 10 or 12 characters long.";
        if (!formData.placementTop) errors.placementTop = "Placement Top is required.";
        if (!formData.district) errors.district = "District is required";
        if (!formData.teamLeader) errors.teamLeader = "Select Team Leader";
        if(!formData.city) errors.city = "please include City";
        if(!formData.extraTel) errors.extraTel = "please include Extra Tel";
        if(!formData.deliveryCode) errors.deliveryCode = "Please Enter DeliveryCode";
        if(!formData.accountHolderName) errors.accountHolderName = "Please Enter AccountHolder Name";
        if(!formData.teamManager) errors.teamManager = "Please Select TeamManager";
        if (!formData.bank) errors.bank = "Bank is required.";
        if (!formData.branch) errors.branch = "Branch is required.";
        if(!formData.unit) errors.unit = "please select UNIT";
        if(!formData.accountType) errors.accountType = "Please select Account Type";
        if (!formData.accountNumber) errors.accountNumber = "Account Number is required.";
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const fetchUserData = async (placementTop) => {
        try {
            const snapshot = await get(child(ref(database), 'users'));
            if (snapshot.exists()) {
                const users = snapshot.val();
                return Object.values(users).find(user => user.placementTop === placementTop) || null;
            }
            return null;
        } catch (error) {
            console.error("Error fetching user data:", error);
            return null;
        }
    };

    const handlePlacementTopSearch = async () => {
        const placementTop = formData.placementTop;
        const userData = await fetchUserData(placementTop);
        if (userData) {
            setFormData({
                ...formData,
                ...userData,
                placementTop: userData.placementTop
            });
            setIsAccountInfoLocked(false);
            setPlacementTopError('');
            
            fetchTeamLeaders(userData.teamManager); // Fetch team leaders when placementTop is valid
        } else {
            setIsAccountInfoLocked(false);
            setPlacementTopError("No user found with this Placement Top.");
        }
    };

    const checkExistingPlacementTop = async (placementTop) => {
        try {
            const snapshot = await get(child(ref(database), 'users'));
            if (snapshot.exists()) {
                const users = snapshot.val();
                return Object.values(users).some(user => user.placementTop === placementTop);
            }
            return false;
        } catch (error) {
            console.error("Error checking existing Placement Top:", error);
            return false;
        }
    };
    const checkExistingNic = async (nic) => {
        try {
            const snapshot = await get(child(ref(database), 'users'));
            if (snapshot.exists()) {
                const users = snapshot.val();
                return Object.values(users).some(user => user.nic === nic);
            }
            return false;
        } catch (error) {
            console.error("Error checking existing NIC:", error);
            return false;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const { placementTop, email, nic } = formData; // Extract email and nic from formData
    
            const isPlacementTopExisting = await checkExistingPlacementTop(placementTop);
            if (isPlacementTopExisting) {
                setPlacementTopError("A user with this Placement Top already exists.");
                return;
            }
    
            const isNicExisting = await checkExistingNic(nic);
            if (isNicExisting) {
                setNicError("A user with this NIC already exists.");
                return;
            }
    
            const newUserKey = uuidv4();
            const password = generatePassword(); // Ensure this function is defined
            const newUserData = {
                ...formData,
                id: newUserKey,
                username: placementTop,
                password
            };
    
            try {
                await set(ref(database, 'users/' + newUserKey), newUserData);
                console.log('Form data submitted:', newUserData);
    
                // Display pop-up with placementTop, password, and email
                const message = `Placement Top: ${placementTop}\nPassword: ${password}\nEmail: ${email}`;
                alert(message);
    
                // Copy to clipboard
                copyToClipboard(message); // Ensure this function is defined
    
                // Navigate to the sendmail page
                navigate('/sendmail', { state: { placementTop, password, email } });
    
            } catch (error) {
                console.error("Error saving data to Firebase:", error);
            }
        }
    };
    
    const generatePassword = () => {
        return Math.random().toString(36).slice(-8);
    };
    const copyToClipboard = (text) => {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        alert('Copied to clipboard!');
    };
    const handleReset = () => {
        setFormData({
            name: '',
            email: '',
            phone: '',
            extraTel: '',
            address: '',
            nic: '',
            teamManager: '',
            district: '',
            city: '',
            deliveryCode: '',
            presenter: '',
            placementTop: '',
            teamLeader: '',
            bank: '',
            branch: '',
            accountType: '',
            accountHolderName: '',
            accountNumber: '',
            unit: ''
        });
        setFormErrors({});
        setPlacementTopError('');
        setNicError('');
        setIsAccountInfoLocked(false);
        setPlaceholders({});
        setTeamLeaders([]);
    };

    return (
        <div style={styles.wrapper}>
            {/* Background Container */}
            <div style={styles.background} />
          {/* Navigation Bar */}
          <nav style={styles.nav}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={logo} alt="Suplex Asia Logo" style={styles.logo} /> {/* Logo Image */}
              <span style={styles.navText}>SUPLEX Asia - Information System</span> {/* Text with common teal */}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column',alignItems: 'flex-end', paddingRight: '20px' }}>
                    <button style={{ marginTop: '2px', padding: '12px', backgroundColor: '#20B2AA', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }} onClick={() => navigate("/")}>Logout</button>

                    </div>
          </nav>
    
        <div style={styles.taskBar}>
            <button style={styles.button} onClick={() => navigate("/")}>HOME</button>
            <button style={styles.button}>SALES REPORT</button>
            <button style={styles.button}>EDUCATION</button>
            <button style={styles.button}>COMMISION HISTORY</button>
            <button style={styles.button}>CUSTOMER REGISTRATION</button>
            <button style={styles.button}>G6</button>
            <div style={styles.dropdownContainer}>
                <button style={styles.button} onClick={toggleDropdown}>SPECIAL TASKS</button>
                {showDropdown && (
                    <div style={styles.dropdown}>
                        {dropdownItems.map((item, index) => (
                            <button
                                key={index}
                                style={{
                                    ...styles.dropdownItem,
                                    backgroundColor: hoverIndex === index ? '#20B2AA' : '#f0f0f0'
                                }}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                            >
                                {item}
                            </button>
                        ))}
                    </div>
                )}
            </div>
           
        </div>

            <div style={styles.mainContent}>
                <form onSubmit={handleSubmit} style={styles.form}>
                    <h2>Registration Form</h2>
                    <div style={styles.section}>
                        <h3>Personal Information</h3>
                        <div style={styles.formGroup}>
                            <label htmlFor="teamManager">Team Manager:</label>
                            <input
                                type="text"
                                id="teamManager"
                                name="teamManager"
                                value={formData.teamManager}
                                onChange={handleInputChange}
                                style={styles.input}
                               readOnly // This makes the text box read-only
                            />
                            {formErrors.teamManager && <p style={styles.errorText}>{formErrors.teamManager}</p>}
                        </div>
                        <div style={styles.formGroup}>
                            <label htmlFor="placementTop">Placement Top:</label>
                            <input
                                type="text"
                                id="placementTop"
                                name="placementTop"
                                value={formData.placementTop}
                                onChange={handleInputChange}
                                //onBlur={handlePlacementTopSearch}
                                style={styles.input}
                            />
                             {formErrors.placementTop && <p style={styles.errorText}>{formErrors.placementTop}</p>}
                        </div>
                        <div style={styles.formGroup}>
                            <label htmlFor="name">Name:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                style={styles.input}
                            />
                            {formErrors.name && <p style={styles.errorText}>{formErrors.name}</p>}
                        </div>
                        <div style={styles.formGroup}>
                            <label htmlFor="email">Email:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                style={styles.input}
                            />
                            {formErrors.email && <p style={styles.errorText}>{formErrors.email}</p>}
                        </div>
                        <div style={styles.formGroup}>
                        <label htmlFor="phone">Phone:</label>
                        <input
                            type="text"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            style={styles.input}
                            maxLength="10" // Limit input to 10 characters
                            pattern="\d*" // Allow only numeric values
                        />
                        {formErrors.phone && <p style={styles.errorText}>{formErrors.phone}</p>}
                    </div>

                        <div style={styles.formGroup}>
                            <label htmlFor="extraTel">Extra Tel:</label>
                            <input
                                type="text"
                                id="extraTel"
                                name="extraTel"
                                value={formData.extraTel}
                                onChange={handleInputChange}
                                style={styles.input}
                            />
                            {formErrors.extraTel && <p style={styles.errorText}>{formErrors.extraTel}</p>}
                        </div>
                        <div style={styles.formGroup}>
                            <label htmlFor="address">Address:</label>
                            <input
                                type="text"
                                id="address"
                                name="address"
                                value={formData.address}
                                onChange={handleInputChange}
                                style={styles.input}
                            />
                            {formErrors.address && <p style={styles.errorText}>{formErrors.address}</p>}
                        </div>
                        <div style={styles.formGroup}>
                            <label htmlFor="nic">NIC:</label>
                            <input
                                type="text"
                                id="nic"
                                name="nic"
                                value={formData.nic}
                                onChange={handleInputChange}
                                maxLength="12"
                                style={styles.input}
                            />
                            {NICError && <p style={styles.errorText}>{NICError}</p>}
                        </div>
                        <div style={styles.formGroup}>
                            <label htmlFor="district">District:</label>
                            <select
                                id="district"
                                name="district"
                                value={formData.district}
                                onChange={handleInputChange}
                                style={styles.select}
                            >
                                <option value="">Select District</option>
                                {districts.map(district => (
                                    <option key={district} value={district}>{district}</option>
                                ))}
                            </select>
                            {formErrors.district && <p style={styles.errorText}>{formErrors.district}</p>}
                        </div>
                        <div style={styles.formGroup}>
                            <label htmlFor="city">City:</label>
                            <input
                                type="text"
                                id="city"
                                name="city"
                                value={formData.city}
                                onChange={handleInputChange}
                                style={styles.input}
                            />
                            {formErrors.city && <p style={styles.errorText}>{formErrors.city}</p>}
                        </div>
                        <div style={styles.formGroup}>
                            <label htmlFor="deliveryCode">Delivery Code:</label>
                            <select
                                type="text"
                                id="deliveryCode"
                                name="deliveryCode"
                                value={formData.deliveryCode}
                                onChange={handleInputChange}
                                style={styles.input}
                            >
                                <option value="">Select DeliveryCode</option>
                                {deliveryCode.map(deliveryCode => (
                                    <option key={deliveryCode} value={deliveryCode}>{deliveryCode}</option>
                                ))}
                            </select>
                            {formErrors.deliveryCode && <p style={styles.errorText}>{formErrors.deliveryCode}</p>}
                        </div>
                        <div style={styles.formGroup}>
                            <label htmlFor="presenter">Presenter:</label>
                            <select
                             id="placementTop"
                            name="presenter"
                            value={formData.presenter}
                            onChange={handleInputChange}
                            style={styles.input}
                        >
                            <option value="">Select Placement Top</option>
                            {placementTop.map(top => (
                                <option key={top} value={top}>
                                    {top}
                                </option>
                            ))}
                        </select>
                        </div>
                    </div>

                    <div style={styles.section}>
                        <h3>Account Information</h3>
                        <div style={styles.formGroup}>
                    <label htmlFor="teamLeader">Team Leader:</label>
                    <select
                        name="teamLeader"
                        value={formData.teamLeader}
                        onChange={handleInputChange}
                        style={styles.select}
                    >
                        <option value="">Select Team Leader</option>
                        {teamLeaders.map(leader => (
                            <option key={leader.placementTop} value={leader.placementTop}>
                                {leader.name} ({leader.placementTop}) - {leader.unit}
                            </option>
                        ))}
                    </select>
                    {formErrors.teamLeader && <p style={styles.errorText}>{formErrors.teamLeader}</p>}
                </div>
                        <div style={styles.formGroup}>
                            <label htmlFor="bank">Bank:</label>
                            <select
                                id="bank"
                                name="bank"
                                value={formData.bank}
                                onChange={handleInputChange}
                                style={styles.select}
                                disabled={isAccountInfoLocked}
                            >
                                <option value="">Select Bank</option>
                                {banks.map(bank => (
                                    <option key={bank} value={bank}>{bank}</option>
                                ))}
                            </select>
                            {formErrors.bank && <p style={styles.errorText}>{formErrors.bank}</p>}
                        </div>
                        <div style={styles.formGroup}>
                            <label htmlFor="branch">Branch:</label>
                            <input
                                type="text"
                                id="branch"
                                name="branch"
                                value={formData.branch}
                                onChange={handleInputChange}
                                style={styles.input}
                                disabled={isAccountInfoLocked}
                            />
                            {formErrors.branch && <p style={styles.errorText}>{formErrors.branch}</p>}
                        </div>
                        <div style={styles.formGroup}>
                            <label htmlFor="accountType">Account Type:</label>
                            <select
                                id="accountType"
                                name="accountType"
                                value={formData.accountType}
                                onChange={handleInputChange}
                                style={styles.select}
                                disabled={isAccountInfoLocked}
                            >
                                <option value="">Select Account Type</option>
                                {accountTypes.map(type => (
                                    <option key={type} value={type}>{type}</option>
                                ))}
                            </select>
                            {formErrors.accountType && <p style={styles.errorText}>{formErrors.accountType}</p>}
                        </div>
                        <div style={styles.formGroup}>
                            <label htmlFor="accountHolderName">Account Holder Name:</label>
                            <input
                                type="text"
                                id="accountHolderName"
                                name="accountHolderName"
                                value={formData.accountHolderName}
                                onChange={handleInputChange}
                                style={styles.input}
                                disabled={isAccountInfoLocked}
                            />
                            {formErrors.accountHolderName && <p style={styles.errorText}>{formErrors.accountHolderName}</p>}
                        </div>
                        <div style={styles.formGroup}>
                            <label htmlFor="accountNumber">Account Number:</label>
                            <input
                                type="text"
                                id="accountNumber"
                                name="accountNumber"
                                value={formData.accountNumber}
                                onChange={handleInputChange}
                                style={styles.input}
                                disabled={isAccountInfoLocked}
                            />
                            {formErrors.accountNumber && <p style={styles.errorText}>{formErrors.accountNumber}</p>}
                        </div>
                        <div style={styles.formGroup}>
                            <label htmlFor="unit">Unit:</label>
                            <select
                                id="unit"
                                name="unit"
                                value={formData.unit}
                                onChange={handleInputChange}
                                style={styles.select}
                            >
                                <option value="">Select Unit</option>
                                {units.map(unit => (
                                    <option key={unit} value={unit}>{unit}</option>
                                ))}
                            </select>
                            {formErrors.unit && <p style={styles.errorText}>{formErrors.unit}</p>}
                        </div>
                    </div>

                    <div style={styles.formGroup}>
                        <button type="submit" style={styles.submitButton}>Submit</button>
                        <button type="button" onClick={handleReset} style={styles.resetButton}>Reset</button>
                    </div>
                </form>
            </div>
            <footer style={styles.footer}>
              <div style={styles.footerContainer}>
                  © 2024 Suplex Ashia. All rights reserved.
              </div>
          </footer>
        </div>
    );
}

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        background: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)', // Gradient from light teal to dark teal
        minHeight: '100vh', // Ensure the container covers the full viewport height
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Distribute content evenly
        alignItems: 'center', // Center horizontally
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        paddingBottom: '50px', // Space for any footer if needed
        width: '1620px', // Fixed width to enforce desktop view
        overflowX: 'hidden', // Prevent horizontal scrollbars
      },
      
      nav: {
        display: 'flex',
        justifyContent: 'space-between', // Space out the title and button
        alignItems: 'center',
        backgroundColor: 'white', // White color for the nav bar
        color: 'black',
        //padding: '0.5em', // Reduced padding
        width: '100%',
        height: '120px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      },
      navList: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        display: 'flex',
        alignItems: 'center', // Align items to the center vertically
      },
      navItem: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '1em', // Adjust margin for spacing
      },
      logo: {
        height: '80px', // Increased height of the logo
        marginRight: '10px',
      },
      navText: {
        backgroundColor: 'transparent', // Removed background color
        color: '#008080', // Dark teal for text
        fontSize: '1.5em', // Default font size
        fontWeight: 'bold',
        // Media query for tablets and small devices
        '@media (max-width: 768px)': {
          fontSize: '1.2em', // Smaller font size for tablets
        },
        // Media query for mobile devices
        '@media (max-width: 480px)': {
          fontSize: '0.1em', // Smaller font size for mobile phones
        },
      },
      wrapper: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'relative', // Position relative to contain absolutely positioned children
        minHeight: '150vh', // Ensure wrapper covers the full viewport height
        background: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)', // Gradient from light teal to dark teal
    },
      taskBar: {
        display: 'flex',
        justifyContent: 'flex-start', // Align items to the start (left)
        margin: '1em 0',
        backgroundColor: '#008080', // Teal color for the task bar
        padding: '0.5em', // Reduced padding
        borderRadius: '8px',
        flexWrap: 'wrap', // Allow items to wrap on smaller screens
        position: 'relative', // Ensure positioning context for children
        paddingLeft: '10px', // Ensure space for the menu button
         justifyContent: 'center',
        alignItems: 'center'
      },
      menuContainer: {
        position: 'absolute', // Position relative to taskBar
        left: '-60px', // Move menuButton outside of the taskBar
        top: '50%', // Center vertically
        transform: 'translateY(-50%)', // Center vertically
        zIndex: 1, // Ensure it is above other elements
      },
      menuButton: {
        backgroundColor: '#008080', // Very light teal
        color: '#E0FFFF', // Dark teal text
        fontWeight: 'bold',
        border: 'none', // Removed border
        borderRadius: '5px',
        cursor: 'pointer',
        padding: '0.6em 0.6em', // Adjusted padding for better appearance
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        position: 'relative',
      },
      menuLine: {
        width: '20px', // Reduced width
        height: '2px', // Reduced height
        backgroundColor: '#E0FFFF', // Dark teal color
        margin: '2px 0',
      },
      menuDropdown: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        backgroundColor: '#E0FFFF', // Very light teal
        border: 'none', // Removed border
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        zIndex: 1,
        minWidth: '100px',
        top: '100%',
        left: 0,
        padding: '0.5em',
      },
      menuDropdownItem: {
        padding: '0.3em 0.6em', // Further reduced padding
        backgroundColor: '#E0FFFF', // Very light teal
        color: '#008080', // Dark teal text
        fontWeight: 'bold',
        border: 'none', // Removed border
        cursor: 'pointer',
        borderRadius: '6px', // Adjusted border radius for smaller padding
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        margin: '0.25em 0', // Reduced margin
        textAlign: 'center',
        fontSize: '0.6em', // Reduced font size
      },
      button: {
        padding: '0.5em 1.5em', // Reduced padding
        backgroundColor: '#E0FFFF', // Very light teal
        color: '#008080', // Dark teal text
        fontWeight: 'bold',
        border: 'none', // Removed border
        cursor: 'pointer',
        margin: '0.5em',
        borderRadius: '5px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        minWidth: '50px', // Reduced min width
        fontSize: '0.9em', // Reduced font size
        textAlign: 'center',
      },
      dropdownContainer: {
        position: 'relative',
        display: 'inline-block',
      },
      dropdown: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        backgroundColor: '#E0FFFF', // Very light teal
        border: 'none', // Removed border
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        zIndex: 1,
        minWidth: '100px',
        top: '100%',
        left: 0,
        padding: '0.5em',
      },
      dropdownItem: {
        padding: '0.3em 1.5em', // Reduced padding
        backgroundColor: '#E0FFFF', // Very light teal
        color: '#008080', // Dark teal text
        fontWeight: 'bold',
        border: 'none', // Removed border
        cursor: 'pointer',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        margin: '0.5em 0',
        textAlign: 'center',
        fontSize: '0.9em', // Reduced font size
       
      },
      logoutButton: {
        bottom: '0px', // Increased distance from the bottom
        right: '25px', // Distance from the right
        backgroundColor: '#008080', // Light teal background
        color: '#E0FFFF', // Dark teal text
        fontWeight: 'bold',
        border: 'none', // Remove border
        borderRadius: '3px', // Slightly rounded corners
        cursor: 'pointer',
        fontSize: '0.9em', // Small font size
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Light shadow
        padding: '0.5em 1em', // Adjusted padding
        paddingLeft: '10px'
        
      },
    imageSection: {
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
        height: '250px',
    },
    imageWrapper: {
        display: 'flex',
        animation: 'scroll 10s linear infinite',
        height: '100%',
    },
    image: {
        flexShrink: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        margin: '0 10px',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '1200px', // Set reasonable max width
        width: '100%', // Allow form to scale with screen size
        margin: '20px auto', // Center form
        backgroundColor: '#e0f2f1',
        padding: '20px', // Adjust padding for smaller screens
        borderRadius: '12px', // Rounded corners
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)', // Shadow for depth
        boxSizing: 'border-box',
        position: 'relative',
        top: '10px',
        width: '900px', // Fixed width to enforce desktop view
        marginBottom: '50px', // Ensures form bottom doesn't touch the footer
    },
    
      formGroup: {
        marginBottom: '8px', // Consistent spacing between form fields
        
      },
      input: {
        width: '100%', // Make input fields span the available width
        maxWidth: '500px', // Limit the maximum width
        padding: '6px', // Padding inside the input
        borderRadius: '4px', // Rounded corners for the input
        border: '1px solid #008080', // Border color teal
        boxSizing: 'border-box', // Proper box sizing to include padding and border in width
        backgroundColor: '#E0FFFF', // Very light teal background
    },
    
      select: {
        borderRadius: '4px',
        border: '1px solid #008080',
        boxSizing: 'border-box',
        backgroundColor: '#E0FFFF',
      },
      searchButton: {
        backgroundColor: '#E0FFFF',
        color: '#008080',
        border: 'none',
        padding: '5px 8px',
        marginTop: '3px',
        cursor: 'pointer',
        borderRadius: '6px',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
        fontWeight: 'bold',
        textAlign: 'center',
      },
      submitButton: {
        backgroundColor: '#008080',
        color: '#fff',
        border: 'none',
        padding: '6px 8px',
        cursor: 'pointer',
        borderRadius: '6px',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
        fontWeight: 'bold',
      },
      resetButton: {
        backgroundColor: '#dc3545',
        color: '#fff',
        border: 'none',
        padding: '6px 10px',
        cursor: 'pointer',
        borderRadius: '6px',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
        fontWeight: 'bold',
        marginLeft: '5px',
      },
      // Media query for smaller screens (mobile view)
      '@media (max-width: 600px)': {
        form: {
          padding: '10px', // Adjust padding for mobile
        },
        input: {
          maxWidth: '100%', // Ensure inputs don't overflow on small screens
        },
        searchButton: {
          width: '100%', // Make buttons full width on mobile
        },
        submitButton: {
          width: '100%', // Make buttons full width on mobile
        },
        resetButton: {
          width: '100%', // Make buttons full width on mobile
          marginLeft: '0', // Remove left margin for mobile
          marginTop: '8px', // Add spacing between buttons
        },
      },
    
    error: {
        color: '#dc3545',
        fontSize: '12px',
        marginTop: '5px',
    },
    errorText: {
        color: 'red', // Text color for error messages
        fontSize: '0.875rem', // Smaller font size for errors
        marginTop: '0.25rem' // Space above the error message
    },
    footer: {
        backgroundColor: '#008080',
        color: '#fff',
        textAlign: 'center',
        padding: '10px',
        width: '100%',
        position: 'fixed',
        bottom: 0,
    },
      footerContainer: {
        margin: '0 auto',
        width: '80%',
    },
};

export default Registration;
