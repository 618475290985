import React from 'react';

const SalesReport = ({ report, userName }) => {
    const { date, type, points, totalPoints } = report;

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Sales Point Report</h1>
            <h2 style={styles.subtitle}>SUPLEX Ashia (Pvt.) Ltd.</h2>
            <p style={styles.userInfo}>
                {userName}
            </p>
            <table style={styles.table}>
                <thead>
                    <tr>
                        <th style={styles.headerCell}>Date</th>
                        <th style={styles.headerCell}>Type</th>
                        <th style={styles.headerCell}>Points</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={styles.cell}>{date}</td>
                        <td style={styles.cell}>{type}</td>
                        <td style={styles.cell}>{points.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td style={styles.totalCell} colSpan="2">Total</td>
                        <td style={styles.totalCell}>{totalPoints}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

const styles = {
    container: {
        backgroundColor: '#e0f7e0',
        borderRadius: '10px',
        padding: '20px',
        textAlign: 'center',
        maxWidth: '800px',
        margin: '0 auto',
    },
    title: {
        fontSize: '2em',
        color: '#333',
    },
    subtitle: {
        fontSize: '1.5em',
        color: '#333',
        marginBottom: '10px',
    },
    userInfo: {
        fontSize: '1em',
        color: '#333',
        marginBottom: '20px',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    headerCell: {
        backgroundColor: '#a8d5a8',
        padding: '10px',
        fontSize: '1em',
        color: '#333',
        borderBottom: '2px solid #6bb96b',
    },
    cell: {
        padding: '10px',
        fontSize: '1em',
        color: '#333',
        borderBottom: '1px solid #a8d5a8',
    },
    totalCell: {
        padding: '10px',
        fontSize: '1em',
        color: '#333',
        fontWeight: 'bold',
        backgroundColor: '#a8d5a8',
    },
};

export default SalesReport;
