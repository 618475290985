// firebase.js
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, get, child } from 'firebase/database';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCrPGno16NssvcajaovZkt3-HPuBOqN0qk",
    authDomain: "suplexashia.firebaseapp.com",
    databaseURL: "https://suplexashia-default-rtdb.firebaseio.com",
    projectId: "suplexashia",
    storageBucket: "suplexashia.appspot.com",
    messagingSenderId: "359629003094",
    appId: "1:359629003094:web:75012dfe8cd92855aa1ef7",
    measurementId: "G-14D6X8SCK2"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app); // Initialize the database
const auth = getAuth(app);

export { database, auth, createUserWithEmailAndPassword, ref, set, get, child }; // Export 'child' function
