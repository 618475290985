import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SalesReport from './SalesReport';
import logo from './suplex-asia-logo.png';
import backgroundImage from './teal background.jpg';

const UserDashboard = () => {
    const location = useLocation();
    const { userName } = location.state || {}; // Extract userName from location.state
    const [report, setReport] = useState({
        date: '2023-08-15',
        type: 'Initial Activation',
        points: 45.00,
        totalPoints: 45
    });

    return (
        <div style={{ ...styles.container, backgroundImage: `url(${backgroundImage})` }}>
            <nav style={styles.nav}>
                <ul style={styles.navList}>
                    <li style={styles.navItem}>
                        <img src={logo} alt="Suplex Asia Logo" style={styles.logo} />
                    </li>
                    <li style={styles.navItem}>
                        <h1>Suplex Ashia - Information System</h1>
                    </li>
                    <li style={styles.navItem}>
                        <span>Currency: LKR (SL Rupee)</span>
                    </li>
                    
                </ul>
            </nav>
            <div style={styles.content}>
                <SalesReport report={report} userName={userName} />
            </div>
        </div>
    );
};

const styles = {
    container: {
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    nav: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
    },
    navList: {
        display: 'flex',
        listStyleType: 'none',
    },
    navItem: {
        margin: '0 10px',
        cursor: 'pointer',
        position: 'relative',
    },
    logo: {
        height: '50px',
    },
    content: {
        padding: '20px',
    },
};

export default UserDashboard;
