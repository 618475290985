import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import logo from './suplex-asia-logo.png'; // Ensure the path is correct

const PersonalDash = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { placementTop } = location.state || {}; // Extract placementTop from location.state
    const [userName, setUserName] = useState(''); // State to store the user's name
    const [openDropdown, setOpenDropdown] = useState(null); // Track which dropdown is open
    const [hoverIndex, setHoverIndex] = useState(null);
    const [showMenu, setShowMenu] = useState(false); // State for the hamburger menu
    const dropdownRef = useRef(null); // Ref for the dropdown
    const menuRef = useRef(null); // Ref for the menu

    useEffect(() => {
        console.log('PlacementTop received:', placementTop); // Debugging: Check if placementTop is being received correctly
        if (placementTop) {
            const db = getDatabase();
            const usersRef = ref(db, 'users');
            onValue(usersRef, (snapshot) => {
                const users = snapshot.val();
                console.log('Fetched users:', users); // Debugging: Check the users fetched from Firebase
                if (users) {
                    const user = Object.values(users).find(user => user.placementTop === placementTop);
                    if (user) {
                        console.log('User found:', user, placementTop); // Debugging: Check the user found with the placementTop
                        setUserName(user.name);
                    } else {
                        console.log('No user found with placementTop:', placementTop); // Debugging: If no user is found
                        setUserName('User not found');
                    }
                } else {
                    console.log('No users data available in Firebase.');
                    setUserName('No user data available');
                }
            }, (error) => {
                console.error('Error fetching data from Firebase:', error); // Debugging: Catch any Firebase errors
                setUserName('Error loading user');
            });
        }
    }, [placementTop]);

    const handleDropdownClick = (index) => {
        setOpenDropdown(openDropdown === index ? null : index);
    };

    const handleMouseEnter = (index) => {
        setHoverIndex(index);
    };

    const handleMouseLeave = () => {
        setHoverIndex(null);
    };

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current && !dropdownRef.current.contains(event.target) &&
            menuRef.current && !menuRef.current.contains(event.target)
        ) {
            setOpenDropdown(null); // Close dropdown if click is outside
            setShowMenu(false); // Also close the hamburger menu if it's open
        }
    };

    useEffect(() => {
        // Add event listener for clicks outside
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Clean up event listener on component unmount
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleChangePassword = () => {
        console.log("Change Password action");
    };

    const handleUpdateInfo = () => {
        console.log("Update Info action");
    };

    const handleInvoiceReport = () => {
        console.log("Invoice Report action");
    };

    const handleAbout = () => {
        navigate('/about'); // Navigate to About page
    };

    const handleProducts = () => {
        console.log("Products action");
    };

    const handlePromotionalPath = () => {
        console.log("Promotional Path action");
    };

    const handleContact = () => {
        console.log("Contact action");
    };
    const handleLogin = () => {
        navigate('/login'); // Navigate to login page
    };

    const dropdownItems = [
        { text: "Change Password", action: handleChangePassword },
        { text: "Update Info", action: handleUpdateInfo },
        { text: "Invoice Report", action: handleInvoiceReport }
    ];

    const menuItems = [
        { text: "About", action: handleAbout },
        { text: "Products", action: handleProducts },
        { text: "Promotional Path", action: handlePromotionalPath },
        { text: "Contact", action: handleContact },
        { text: "Login", action: handleLogin }
    ];

    const handleViewReport = () => {
        navigate('/termsandconditions', { state: { placementTop, userName } });
    };
    

    return (
        <div style={styles.wrapper}>
      {/* Background Container */}
      <div style={styles.background} />
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: '#E0FFFF', // Background color for the bar
                padding: '10px',
            }}>
                <div style={{ 
                    display: 'flex',
                    justifyContent: 'space-between', // Space out the title and button
                    alignItems: 'center',
                    backgroundColor: '#FFFAFA', // White color for the nav bar
                    color: 'black',
                    //padding: '0.5em', // Reduced padding
                    width: '100%',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',

                }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={logo} alt="Logo" style={{ height: '70px', marginRight: '10px' }} />
                        <span style={styles.title}>SUPLEX Ashia</span> {/* Text with common teal */}
                        <span style={{color: 'teal', fontSize: '1.6em', fontWeight: 'bold',alignItems: 'center',paddingLeft:'10px'}}>InformationSystem</span> {/* Text with common teal */}
                    </div>
                    <p style={{ margin: '0 5px', textAlign: 'center', fontSize: '1.0em', fontWeight: 'bold', color: 'teal' }}>
                            Currency: LKR (SL Rupee)
                        </p>
                    <div style={{ display: 'flex', flexDirection: 'column',alignItems: 'flex-end', paddingRight: '20px' }}>
                    <button style={{ marginTop: '2px', padding: '12px', backgroundColor: '#20B2AA', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }} onClick={() => navigate("/")}>Logout</button>
                    <p style={{ margin: '0', color: '#008080', fontSize: '1.0em', fontWeight: 'bold', textAlign:'right' }}>Welcome</p>
                    <p style={{ margin: '0', color: '#008080', fontSize: '1.0em', fontWeight: 'bold' }}>
                        {userName || 'Loading..'}!
                    </p>
                    {placementTop && (
                        <p style={{ margin: '0', color: '#008080', fontSize: '1.0em', fontWeight: 'bold' }}>
                        ({placementTop})
                        </p>
                    )}
                    </div>
                </div>
            </div>

            <div style={styles.taskBar}>
                <div style={styles.menuContainer} ref={menuRef}>
                    <button style={styles.menuButton} onClick={toggleMenu}>
                        <div style={styles.menuLine}></div>
                        <div style={styles.menuLine}></div>
                        <div style={styles.menuLine}></div>
                    </button>
                    {showMenu && (
                        <div style={styles.menuDropdown}>
                            {menuItems.map((item, index) => (
                                <button
                                    key={index}
                                    style={{
                                        ...styles.menuDropdownItem,
                                        backgroundColor: hoverIndex === index ? '#20B2AA' : '#E0FFFF' // Dark teal on hover
                                    }}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                    onClick={item.action} // Call the respective action
                                >
                                    {item.text}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
                <button style={styles.button} onClick={() => navigate("/")}>HOME</button> {/* Navigate to Home page */}
                <button style={styles.button} onClick={handleViewReport}>SALES REPORT</button>
                <button style={styles.button} onClick={() => setOpenDropdown(null)}>COMMISION HISTORY</button>
                <button style={styles.button} onClick={() => navigate("/termsandconditions")}>CUSTOMER REGISTRATION</button>
                <button style={styles.button} onClick={() => setOpenDropdown(null)}>G6</button>
                <div style={styles.dropdownContainer} ref={dropdownRef}>
                    <button style={styles.button} onClick={() => handleDropdownClick(0)}>SPECIAL TASKS</button>
                    {openDropdown === 0 && (
                        <div style={styles.dropdown}>
                            {dropdownItems.map((item, index) => (
                                <button
                                    key={index}
                                    style={{
                                        ...styles.dropdownItem,
                                        backgroundColor: hoverIndex === index ? '#20B2AA' : '#E0FFFF' // Dark teal on hover
                                    }}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                    onClick={item.action} // Call the respective action
                                >
                                    {item.text}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
                
            </div>

            <main style={{ 
    backgroundColor: '#f5f5f5', 
    padding: '20px', 
    width: '60%', 
    height: '60%',
    maxWidth: '1200px', 
    alignItems: 'center',
    margin: '0 auto',  // Centers the main element horizontally
    marginBottom: '20px', // Space between main content and footer
    borderRadius: '20px'
}}>
    <p style={{ textAlign: 'center', color: '#008080',fontSize: '30px' }}>Collect Health Points</p>
    <p style={{ textAlign: 'center', color: '#008080',fontSize: '30px' }}>& </p>
    <p style={{ textAlign: 'center', color: '#008080',fontSize: '30px' }}>Grab your Health Level by Next Month!</p>
</main>


    <footer style={styles.footer}>
                <div style={styles.footerContainer}>
                    © 2024 Suplex Ashia. All rights reserved.
                </div>
            </footer>
        </div>
    );
};

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        background: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)', // Gradient from light teal to dark teal
        //minHeight: '100vh', // Ensure the container covers the full viewport height
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Distribute content evenly
        alignItems: 'center', // Center horizontally
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        paddingBottom: '50px', // Space for any footer if needed
        width: '1620px', // Fixed width to enforce desktop view
        overflowX: 'hidden', // Prevent horizontal scrollbars
      },
          nav: {
            display: 'flex',
            justifyContent: 'space-between', // Space out the title and button
            alignItems: 'center',
            backgroundColor: '#FFFAFA', // White color for the nav bar
            color: 'black',
            height:'120px',
            //padding: '0.5em', // Reduced padding
            width: '100%',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          },
          navList: {
            listStyle: 'none',
            padding: 0,
            margin: 0,
            display: 'flex',
            alignItems: 'center', // Align items to the center vertically
          },
          navItem: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '1em', // Adjust margin for spacing
          },
          logo: {
            height: '80px', // Increased height of the logo
            marginRight: '10px',
          },
          navText: {
            backgroundColor: 'transparent', // Removed background color
            color: '#008080', // Dark teal for text
            fontSize: '1.7em', // Default font size
            fontWeight: 'bold',
            // Media query for tablets and small devices
            '@media (max-width: 768px)': {
              fontSize: '1.2em', // Smaller font size for tablets
            },
            // Media query for mobile devices
            '@media (max-width: 480px)': {
              fontSize: '0.1em', // Smaller font size for mobile phones
            },
          },
        wrapper: {
          fontFamily: 'Arial, sans-serif',
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
          position: 'relative', // Position relative to contain absolutely positioned children
          minHeight: '100vh', // Ensure wrapper covers the full viewport height
          background: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)', // Gradient from light teal to dark teal
      },
      taskBar: {
        display: 'flex',
        justifyContent: 'center', // Center items horizontally
        alignItems: 'center', // Center items vertically
        margin: '1em 0',
        backgroundColor: '#008080', // Teal color for the task bar
        padding: '0.5em', // Reduced padding
        borderRadius: '8px',
        flexWrap: 'wrap', // Allow items to wrap on smaller screens
        position: 'relative', // Ensure positioning context for children
      },
      
      menuContainer: {
        position: 'absolute', // Position relative to taskBar
        left: '1em', // Add space from the left edge of taskBar
        top: '50%', // Center vertically
        transform: 'translateY(-50%)', // Center vertically
        zIndex: 1, // Ensure it is above other elements
        display: 'flex', // Ensure menuButton is centered
        alignItems: 'center',
        justifyContent: 'center',
      },
      
      menuButton: {
        backgroundColor: '#008080', // Teal color
        color: '#E0FFFF', // Light teal text
        fontWeight: 'bold',
        border: 'none', // Removed border
        borderRadius: '5px',
        cursor: 'pointer',
        padding: '0.6em 0.6em', // Adjusted padding
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        position: 'relative',
        margin: '0 1em', // Add space on left and right sides
      },
      
      menuLine: {
        width: '20px', // Line width
        height: '2px', // Line height
        backgroundColor: '#E0FFFF', // Light teal color
        margin: '2px 0',
      },
      
      menuDropdown: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        backgroundColor: '#E0FFFF', // Light teal color
        border: 'none', // Removed border
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        zIndex: 1,
        minWidth: '100px',
        top: '100%',
        left: '0',
        padding: '0.5em',
      },
      
      menuDropdownItem: {
        padding: '0.3em 0.6em', // Padding
        backgroundColor: '#E0FFFF', // Light teal color
        color: '#008080', // Dark teal text
        fontWeight: 'bold',
        border: 'none', // Removed border
        cursor: 'pointer',
        borderRadius: '6px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        margin: '0.25em 0', // Margin
        textAlign: 'center',
        fontSize: '0.9em', // Font size
      },
      
        button: {
          padding: '0.5em 1.5em', // Reduced padding
          backgroundColor: '#E0FFFF', // Very light teal
          color: '#008080', // Dark teal text
          fontWeight: 'bold',
          border: 'none', // Removed border
          cursor: 'pointer',
          margin: '0.5em',
          borderRadius: '5px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          minWidth: '50px', // Reduced min width
          fontSize: '0.9em', // Reduced font size
          textAlign: 'center',
        },
        dropdownContainer: {
          position: 'relative',
          display: 'inline-block',
        },
        dropdown: {
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          backgroundColor: '#E0FFFF', // Very light teal
          border: 'none', // Removed border
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          zIndex: 1,
          minWidth: '100px',
          top: '100%',
          left: 0,
          padding: '0.5em',
        },
        dropdownItem: {
          padding: '0.3em 1.5em', // Reduced padding
          backgroundColor: '#E0FFFF', // Very light teal
          color: '#008080', // Dark teal text
          fontWeight: 'bold',
          border: 'none', // Removed border
          cursor: 'pointer',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          margin: '0.5em 0',
          textAlign: 'center',
          fontSize: '0.9em', // Reduced font size
         
        },
    header: {
        textAlign: 'center',
        marginTop: '60px', // Adjust for navbar height
        padding: '20px',
        width: '100%',
        backgroundColor: '#E0FFFF',
    },
    title: {
        backgroundColor: 'transparent', // Removed background color
        color: '#008080', // Dark teal for text
        fontSize: '1.6em', // Increased font size
        fontWeight: 'bold',
      },
    heading: {
        fontSize: '2em',
        color: '#008080',
        margin: 0,
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gap: '20px',
        padding: '20px',
        width: '80%',
        margin: '20px auto',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    image: {
        width: '100%',
        height: 'auto',
        borderRadius: '8px',
    },
    details: {
        marginTop: '10px',
    },
    productName: {
        fontSize: '1.2em',
        color: '#008080',
        margin: '0 0 10px',
    },
    productDescription: {
        color: '#333',
        fontSize: '1em',
        margin: 0,
    },
    footer: {
        backgroundColor: '#008080',
        color: '#fff',
        textAlign: 'center',
        padding: '10px',
        width: '100%',
        position: 'fixed',
        bottom: 0,
    },
    footerContainer: {
        margin: '0 auto',
        width: '80%',
    },
  
};

export default PersonalDash;
