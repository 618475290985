import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';

const SendMail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { placementTop, password, email } = location.state || {};

    // Static sender name
    const senderName = 'SuplexAshia PVT (LTD)';

    // Prepare parameters for EmailJS
    const params = {
        sendername: senderName,
        to: email,
        subject: 'Account Creation Confirmation',
        replyto: email,
        message: `
            Dear User,

            Your account has been created successfully.

            Your UserID: ${placementTop}
            Password: ${password}

            If you have any questions, please contact us.

            Best regards,
            SuplexAshia PVT (LTD)
        `,
    };

    const handleSendEmail = (e) => {
        e.preventDefault();

        // Send email using EmailJS
        emailjs.send('service_qo8iv3h', 'template_hun8ve9', params, 'aMFNkxNYcqJS3kabm')
            .then((response) => {
                console.log('Email sent successfully:', response);
                alert('Email sent successfully!');
            })
            .catch((error) => {
                console.error('Error sending email:', error);
                alert('Error sending email.');
            });
    };

    const handleCancel = () => {
        navigate('/registration');
    };

    return (
        <div className="container">
            <h1 className="title">Send Email</h1>
            <form onSubmit={handleSendEmail} className="form">
                <div className="form-group">
                    <label>Sender Name:</label>
                    <input
                        type="text"
                        value={senderName}
                        readOnly
                    />
                </div>
                <div className="form-group">
                    <label>To (Email):</label>
                    <input
                        type="text"
                        value={email || ''}
                        readOnly
                    />
                </div>
                <div className="form-group">
                    <label>SRI User ID:</label>
                    <input
                        type="text"
                        value={placementTop || ''}
                        readOnly
                    />
                </div>
                <div className="form-group">
                    <label>Message:</label>
                    <textarea
                        cols="40"
                        rows="8"
                        value={`
                            Dear User,

                            Your account has been created successfully.

                            Placement Top: ${placementTop}
                            Password: ${password}

                            If you have any questions, please contact us.

                            Best regards,
                            SuplexAshiaWebsite
                        `}
                        readOnly
                    ></textarea>
                </div>
                <div className="button-group">
                    <button type="submit">Send</button>
                    <button type="button" onClick={handleCancel} className="cancel-button">Cancel</button>
                </div>
            </form>
            <style jsx global>{`
                    body {
                    margin: 1000;
                    padding: 20;
                    height: 100vh;
                    background: linear-gradient(to bottom, #E0FFFF 70%, #008080);
                    background-size: cover;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            `}</style>
            <style jsx>{`
                .container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 20px auto;
                    padding: 100px;
                    width: 70%;
                    max-width: 500px;
                    border-radius: 8px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    background: #E0FFFF;
                }
                .title {
                    margin-bottom: 20px;
                    color: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)', /* Teal color */
                }
                .form {
                    width: 100%;
                }
                .form-group {
                    margin-bottom: 15px;
                }
                .form-group label {
                    display: block;
                    margin-bottom: 5px;
                    font-weight: bold;
                }
                .form-group input, .form-group textarea {
                    width: 100%;
                    padding: 8px;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                }
                .button-group {
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between;
                }
                .button-group button {
                    padding: 10px 20px;
                    border: none;
                    border-radius: 4px;
                    font-size: 16px;
                    cursor: pointer;
                }
                .button-group button[type="submit"] {
                    background-color: #008080; /* Teal color */
                    color: white;
                }
                .button-group .cancel-button {
                    background-color: #d9534f; /* Bootstrap danger color */
                    color: white;
                }
                .button-group .cancel-button:hover {
                    background-color: #c9302c; /* Darker red */
                }
            `}</style>
        </div>
    );
};

export default SendMail;
