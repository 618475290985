import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './suplex-asia-logo.png'; // Import the logo image
import backgroundImage from './teal background.jpg'; // Import the background image
import registernote1 from './resgisternote1.jpg'; // Import registration image 1
import registernote2 from './registernote2.jpg'; // Import registration image 2

function About() {
    const navigate = useNavigate();
    const [showDropdown, setShowDropdown] = useState(false);
    const [hoverIndex, setHoverIndex] = useState(null);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleMouseEnter = (index) => {
        setHoverIndex(index);
    };

    const handleMouseLeave = () => {
        setHoverIndex(null);
    };

    const dropdownItems = ["Change Password", "Update Info", "Invoice Report"];

    return (
        <div style={{ ...styles.container, backgroundImage: `url(${backgroundImage})` }}>
            {/* Navigation Bar */}
            <nav style={styles.nav}>
                <ul style={styles.navList}>
                    <li style={{ ...styles.navItem, ...styles.navBrand }}>
                        <img src={logo} alt="Suplex Asia Logo" style={styles.logo} /> {/* Logo Image */}
                        <span style={styles.navText}>SUPLEX Ashia - Information System</span> {/* Text with teal background */}
                    </li>
                </ul>
            </nav>

            {/* Task Bar */}
            <div style={styles.taskBar}>
                <button style={styles.button} onClick={() => navigate("/")}>HOME</button>
                <button style={styles.button}>SALES REPORT</button>
                <button style={styles.button}>PRODUCTS</button>
                <button style={styles.button}>PROMOTIONAL PATH</button>
                <button style={styles.button}>CONTACT</button>
                <div style={styles.dropdownContainer}>
                    <button style={styles.button} onClick={toggleDropdown}>SPECIAL TASKS</button>
                    {showDropdown && (
                        <div style={styles.dropdown}>
                            {dropdownItems.map((item, index) => (
                                <button
                                    key={index}
                                    style={{
                                        ...styles.dropdownItem,
                                        backgroundColor: hoverIndex === index ? '#20B2AA' : '#E0FFFF' // Dark teal on hover
                                    }}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    {item}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
                <button style={styles.button}>Logout</button>
            </div>

            {/* Main Content */}
            <div style={styles.mainContent}>
                {/* Vision */}
                <section style={{ ...styles.section, backgroundColor: '#E0FFFF' }}>
                    <h2>Vision</h2>
                    <p>Our vision is to be the leading provider of innovative solutions in the industry.</p>
                </section>
                {/* Mission */}
                <section style={{ ...styles.section, backgroundColor: '#E0FFFF' }}>
                    <h2>Mission</h2>
                    <p>Our mission is to deliver high-quality products that bring value to our customers.</p>
                </section>
                {/* Objectives */}
                <section style={{ ...styles.section, backgroundColor: '#E0FFFF' }}>
                    <h2>Objectives</h2>
                    <ul>
                        <li>Provide excellent customer service.</li>
                        <li>Innovate continuously to meet market needs.</li>
                        <li>Ensure sustainable growth and profitability.</li>
                    </ul>
                </section>
                {/* Chairman's Message */}
                <section style={{ ...styles.section, backgroundColor: '#E0FFFF' }}>
                    <h2>Chairman's Message</h2>
                    <p>Dear valued stakeholders, we are committed to driving progress and achieving excellence in all our endeavors.</p>
                </section>
                {/* Registration Images */}
                <div style={styles.imageWrapper}>
                    <div style={styles.imageContainer}>
                        <img src={registernote1} alt="Register Note 1" style={styles.image} />
                       <img src={registernote2} alt="Register Note 2" style={styles.image} />
                    </div>
                </div>
            </div>

            {/* Footer */}
            <footer style={styles.footer}>
                <div style={styles.footerContainer}>
                    <p>© 2024 Suplex Ashia. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
}

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
    background: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)', // Gradient from light teal to dark teal
    minHeight: '100vh', // Ensure the container covers the full viewport height
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', // Distribute content evenly
    alignItems: 'center', // Center horizontally
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingBottom: '50px', // Space for any footer if needed
  },
  nav: {
    display: 'flex',
    justifyContent: 'space-between', // Space out the title and button
    alignItems: 'center',
    backgroundColor: 'white', // White color for the nav bar
    color: 'black',
    padding: '0.5em', // Reduced padding
    width: '100%',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  navList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center', // Align items to the center vertically
  },
  navItem: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1em', // Adjust margin for spacing
  },
  logo: {
    height: '80px', // Increased height of the logo
    marginRight: '10px',
  },
  navText: {
    backgroundColor: 'transparent', // Removed background color
    color: '#008080', // Dark teal for text
    fontSize: '1.5em', // Default font size
    fontWeight: 'bold',
    // Media query for tablets and small devices
    '@media (max-width: 768px)': {
      fontSize: '1.2em', // Smaller font size for tablets
    },
    // Media query for mobile devices
    '@media (max-width: 480px)': {
      fontSize: '0.1em', // Smaller font size for mobile phones
    },
  },
  
  taskBar: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center', // Ensure items are aligned properly
    margin: '1em 0',
    backgroundColor: '#008080',
    //padding: '0.5em 10px', // Adjusted padding
    borderRadius: '8px',
    position: 'relative',
    overflow: 'visible', // Or 'hidden' depending on needs
  },
  menuContainer: {
    position: 'absolute', // Position relative to taskBar
    left: '-60px', // Move menuButton outside of the taskBar
    top: '50%', // Center vertically
    transform: 'translateY(-50%)', // Center vertically
    zIndex: 1, // Ensure it is above other elements
  },
  menuButton: {
    backgroundColor: '#008080', // Very light teal
    color: '#E0FFFF', // Dark teal text
    fontWeight: 'bold',
    border: 'none', // Removed border
    borderRadius: '5px',
    cursor: 'pointer',
    padding: '0.6em 0.6em', // Adjusted padding for better appearance
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    position: 'relative',
  },
  menuLine: {
    width: '20px', // Reduced width
    height: '2px', // Reduced height
    backgroundColor: '#E0FFFF', // Dark teal color
    margin: '2px 0',
  },
  menuDropdown: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    backgroundColor: '#E0FFFF', // Very light teal
    border: 'none', // Removed border
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 1,
    minWidth: '100px',
    top: '100%',
    left: 0,
    padding: '0.5em',
  },
  menuDropdownItem: {
    padding: '0.3em 0.6em', // Further reduced padding
    backgroundColor: '#E0FFFF', // Very light teal
    color: '#008080', // Dark teal text
    fontWeight: 'bold',
    border: 'none', // Removed border
    cursor: 'pointer',
    borderRadius: '6px', // Adjusted border radius for smaller padding
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    margin: '0.25em 0', // Reduced margin
    textAlign: 'center',
    fontSize: '0.6em', // Reduced font size
  },
  button: {
    padding: '0.5em 1.5em', // Reduced padding
    backgroundColor: '#E0FFFF', // Very light teal
    color: '#008080', // Dark teal text
    fontWeight: 'bold',
    border: 'none', // Removed border
    cursor: 'pointer',
    margin: '0.5em',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    minWidth: '50px', // Reduced min width
    flexGrow: 0,
    fontSize: '0.5em', // Reduced font size
    flexbasics: '10%',
    textAlign: 'center',
  },
  dropdownContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    backgroundColor: '#E0FFFF', // Very light teal
    border: 'none', // Removed border
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 1,
    minWidth: '100px',
    top: '100%',
    left: 0,
    padding: '0.5em',
  },
  dropdownItem: {
    padding: '0.3em 1.5em', // Reduced padding
    backgroundColor: '#E0FFFF', // Very light teal
    color: '#008080', // Dark teal text
    fontWeight: 'bold',
    border: 'none', // Removed border
    cursor: 'pointer',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    margin: '0.5em 0',
    textAlign: 'center',
    fontSize: '0.6em', // Reduced font size
  },
  logoutButton: {
    bottom: '0px', // Increased distance from the bottom
    right: '10px', // Distance from the right
    backgroundColor: '#E0FFFF', // Light teal background
    color: '#008080', // Dark teal text
    fontWeight: 'bold',
    border: 'none', // Remove border
    borderRadius: '3px', // Slightly rounded corners
    cursor: 'pointer',
    fontSize: '0.8em', // Small font size
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Light shadow
    padding: '0.5em 1em', // Adjusted padding
  },
  mainContent: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '1em',
    marginBottom: '50px', // Ensure space for the footer
  },
  section: {
    width: '100%',
    padding: '2em',
    textAlign: 'center',
    backgroundColor: '#E0FFFF', // Light teal background for sections
    marginBottom: '1em',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    border: '2px solid #008080', // Teal outline
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    paddingLeft: '1em', // Add padding from the left edge
  },
  imageContainer: {
    gap: '1em',
    display: 'flex',
    flexDirection: 'row', // Changed 'in-line' to 'row' for horizontal layout
    alignItems: 'center',
    justifyContent: 'center', // Centers images horizontally
    flexWrap: 'wrap', // Ensures images wrap on smaller screens
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
    padding: '10px', // Optional padding for spacing
  },
  
  // Media queries for responsiveness
  '@media (max-width: 768px)': {
    imageContainer: {
      flexDirection: 'column', // Stacks images vertically on smaller screens
    },
  },
  
  '@media (max-width: 480px)': {
    imageContainer: {
      gap: '0.5em', // Reduce gap between images on very small screens
    },
  },
  image: {
    maxWidth: '300px', // Reduced max width
    height: 'auto',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    border: '2px solid #008080', // Teal outline
  },
  footer: {
    backgroundColor: '#008080',
    color: '#fff',
    textAlign: 'center',
    padding: '10px',
    width: '100%',
    position: 'fixed',
    bottom: 0,
},
  footerContainer: {
    margin: '0 auto',
    width: '80%',
},
};
// Define keyframes for scrolling animation
const styleSheet = document.styleSheets[0];
const keyframes = `
@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}
`;
styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
export default About;
