import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import SalesReport from './SalesReport';
import logo from './suplex-asia-logo.png';
import backgroundImage from './teal background.jpg';
import cosmetic1 from './cosmetic1.jpg';
import cosmetic2 from './cosmetic2.jpg';
import cosmetic3 from './cosmetic3.jpg';
import cosmetic4 from './cosmetic4.jpg';
import cosmetic5 from './cosmetic5.jpg';

const Showname = () => {
    const location = useLocation();
    const { placementTop } = location.state || {};
    const [userName, setUserName] = useState('');
    const [report, setReport] = useState({
        date: '2023-08-15',
        type: 'Initial Activation',
        points: 45.00,
        totalPoints: 45
    });

    useEffect(() => {
        if (placementTop) {
            const db = getDatabase();
            const usersRef = ref(db, 'users');
            onValue(usersRef, (snapshot) => {
                const users = snapshot.val();
                const user = Object.values(users).find(user => user.placementTop === placementTop);
                if (user) {
                    setUserName(user.name);
                }
            });
        }
    }, [placementTop]);

    return (
        <div style={{ ...styles.container, backgroundImage: `url(${backgroundImage})` }}>
            <nav style={styles.nav}>
                <ul style={styles.navList}>
                    <li style={styles.navItem}>
                        <img src={logo} alt="Suplex Asia Logo" style={styles.logo} />
                    </li>
                    <li style={styles.navItem}>
                        {userName && <h2>Welcome, {userName}!</h2>}
                    </li>
                </ul>
            </nav>
            <div style={styles.content}>
                <SalesReport report={report} userName={userName} />
                <div style={styles.imagesContainer}>
                    <img src={cosmetic1} alt="Cosmetic 1" style={styles.image} />
                    <img src={cosmetic2} alt="Cosmetic 2" style={styles.image} />
                    <img src={cosmetic3} alt="Cosmetic 3" style={styles.image} />
                    <img src={cosmetic4} alt="Cosmetic 4" style={styles.image} />
                    <img src={cosmetic5} alt="Cosmetic 5" style={styles.image} />
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    nav: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
    },
    navList: {
        display: 'flex',
        listStyleType: 'none',
    },
    navItem: {
        margin: '0 10px',
        cursor: 'pointer',
        position: 'relative',
    },
    logo: {
        height: '50px',
    },
    content: {
        padding: '20px',
    },
    imagesContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
    },
    image: {
        maxWidth: '200px',
        margin: '10px',
        borderRadius: '10px',
    },
};

export default Showname;
