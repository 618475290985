import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './suplex-asia-logo.png'; // Import the logo image
import cosmetic1 from './cosmetic1.jpg';
import cosmetic2 from './cosmetic2.jpg';
import cosmetic3 from './cosmetic3.jpg';
import cosmetic4 from './cosmetic4.jpg';
import cosmetic5 from './cosmetic5.jpg';

function Products() {
    const [showMenu, setShowMenu] = useState(false);
    const [hoverIndex, setHoverIndex] = useState(null);
    const [openDropdown, setOpenDropdown] = useState(null);
    const menuRef = useRef(null);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleMouseEnter = (index) => setHoverIndex(index);
    const handleMouseLeave = () => setHoverIndex(null);
    const handleDropdownClick = (index) => setOpenDropdown(openDropdown === index ? null : index);

    const menuItems = [
        { text: 'About', action: () => navigate("/about") },
        { text: 'Products', action: () => navigate("/products") },
        { text: 'Activities', action: () => setOpenDropdown(null) },
        { text: 'Buy Now', action: () => setOpenDropdown(null) }
    ];

    const dropdownItems = [
        { text: 'Task 1', action: () => console.log('Task 1') },
        { text: 'Task 2', action: () => console.log('Task 2') },
        { text: 'Task 3', action: () => console.log('Task 3') }
    ];

    return (
      <div style={styles.wrapper}>
      {/* Background Container */}
      <div style={styles.background} />
            {/* Navigation Bar */}
            <nav style={styles.nav}>
                <ul style={styles.navList}>
                    <li style={styles.navItem}>
                        <img src={logo} alt="Suplex Asia Logo" style={styles.logo} />
                        <span style={styles.navText}>SUPLEX Ashia - Information System</span>
                    </li>
                </ul>
            </nav>

            {/* Task Bar */}
            <div style={styles.taskBar}>
                <div style={styles.menuContainer} ref={menuRef}>
                    <button style={styles.menuButton} onClick={() => setShowMenu(!showMenu)}>
                        <div style={styles.menuLine}></div>
                        <div style={styles.menuLine}></div>
                        <div style={styles.menuLine}></div>
                    </button>
                    {showMenu && (
                        <div style={styles.menuDropdown}>
                            {menuItems.map((item, index) => (
                                <button
                                    key={index}
                                    style={{
                                        ...styles.menuDropdownItem,
                                        backgroundColor: hoverIndex === index ? '#20B2AA' : '#E0FFFF'
                                    }}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                    onClick={item.action}
                                >
                                    {item.text}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
                <button style={styles.button} onClick={() => navigate("/")}>HOME</button>
                <button style={styles.button} onClick={() => navigate("/products")}>PRODUCTS</button>
                <button style={styles.button} onClick={() => navigate("/activities")}>ACTIVITIES</button>
                <button style={styles.button} onClick={() => navigate("/buynow")}>BuyNow</button>
                <div style={styles.dropdownContainer} ref={dropdownRef}>
                    <button style={styles.button} onClick={() => handleDropdownClick(0)}>SpecialTask</button>
                    {openDropdown === 0 && (
                        <div style={styles.dropdown}>
                            {dropdownItems.map((item, index) => (
                                <button
                                    key={index}
                                    style={{
                                        ...styles.dropdownItem,
                                        backgroundColor: hoverIndex === index ? '#20B2AA' : '#E0FFFF'
                                    }}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                    onClick={item.action}
                                >
                                    {item.text}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <header style={styles.header}>
                <h1 style={styles.heading}>Our Products</h1>
            </header>
            <div style={styles.grid}>
                <div style={styles.item}>
                    <img src={cosmetic1} alt="Cosmetic 1" style={styles.image} />
                    <div style={styles.details}>
                        <h2 style={styles.productName}>Cosmetic 1</h2>
                        <p style={styles.productDescription}>A premium cosmetic product that enhances your beauty.</p>
                    </div>
                </div>
                <div style={styles.item}>
                    <img src={cosmetic2} alt="Cosmetic 2" style={styles.image} />
                    <div style={styles.details}>
                        <h2 style={styles.productName}>Cosmetic 2</h2>
                        <p style={styles.productDescription}>High-quality cosmetic with nourishing ingredients.</p>
                    </div>
                </div>
                <div style={styles.item}>
                    <img src={cosmetic3} alt="Cosmetic 3" style={styles.image} />
                    <div style={styles.details}>
                        <h2 style={styles.productName}>Cosmetic 3</h2>
                        <p style={styles.productDescription}>The perfect choice for a glowing complexion.</p>
                    </div>
                </div>
                <div style={styles.item}>
                    <img src={cosmetic4} alt="Cosmetic 4" style={styles.image} />
                    <div style={styles.details}>
                        <h2 style={styles.productName}>Cosmetic 4</h2>
                        <p style={styles.productDescription}>Luxurious and effective, designed for all skin types.</p>
                    </div>
                </div>
                <div style={styles.item}>
                    <img src={cosmetic5} alt="Cosmetic 5" style={styles.image} />
                    <div style={styles.details}>
                        <h2 style={styles.productName}>Cosmetic 5</h2>
                        <p style={styles.productDescription}>Revitalize your skin with this exquisite cosmetic product.</p>
                    </div>
                </div>
            </div>
            <footer style={styles.footer}>
                <div style={styles.footerContainer}>
                    © 2024 Suplex Ashia. All rights reserved.
                </div>
            </footer>
        </div>
    );
}

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        background: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        paddingBottom: '50px',
        width: '1620px',
        overflowX: 'hidden',
    },
    nav: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#FFFAFA',
        color: 'black',
        height: '120px',
        width: '100%',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    navList: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        display: 'flex',
        alignItems: 'center',
    },
    navItem: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '1em',
    },
    logo: {
        height: '80px',
        marginRight: '10px',
    },
    navText: {
        color: '#008080',
        fontSize: '1.5em',
        fontWeight: 'bold',
    },
    wrapper: {
      fontFamily: 'Arial, sans-serif',
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
      position: 'relative', // Position relative to contain absolutely positioned children
      minHeight: '100vh', // Ensure wrapper covers the full viewport height
      background: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)', // Gradient from light teal to dark teal
  },
    taskBar: {
      display: 'flex',
      justifyContent: 'center', // Center items horizontally
      alignItems: 'center', // Center items vertically
      margin: '1em 0',
      backgroundColor: '#008080', // Teal color for the task bar
      padding: '0.5em', // Reduced padding
      borderRadius: '8px',
      flexWrap: 'wrap', // Allow items to wrap on smaller screens
      position: 'relative', // Ensure positioning context for children
    },
    button: {
      padding: '0.5em 1.5em', // Reduced padding
      backgroundColor: '#E0FFFF', // Very light teal
      color: '#008080', // Dark teal text
      fontWeight: 'bold',
      border: 'none', // Removed border
      cursor: 'pointer',
      margin: '0.5em',
      borderRadius: '5px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      minWidth: '50px', // Reduced min width
      fontSize: '1.0em', // Reduced font size
      textAlign: 'center',
    },
    header: {
        textAlign: 'center',
        margin: '2em 0',
    },
    heading: {
        fontSize: '2.5em',
        color: '#333',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '20px',
        padding: '0 20px',
    },
    item: {
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      padding: '2em', // Increased padding for larger item size
      textAlign: 'center',
      maxWidth: '400px', // Set a maximum width to control size
      margin: '1em auto', // Center item and provide spacing between items
  },
    image: {
      width: '100%',  // Keep this for responsiveness
      maxWidth: '200px', // Set a maximum width
      height: 'auto',    // Keep the aspect ratio
      borderRadius: '8px',
  },
    details: {
        marginTop: '10px',
    },
    productName: {
        fontSize: '1.5em',
        color: '#008080',
    },
    productDescription: {
        fontSize: '1em',
        color: '#555',
    },
    footer: {
      backgroundColor: '#008080',
      color: '#fff',
      textAlign: 'center',
      padding: '10px',
      width: '100%',
      position: 'fixed',
      bottom: 0,
  },
    footerContainer: {
      margin: '0 auto',
      width: '100%',
  },
  menuContainer: {
    position: 'absolute', // Position relative to taskBar
    left: '1em', // Add space from the left edge of taskBar
    top: '50%', // Center vertically
    transform: 'translateY(-50%)', // Center vertically
    zIndex: 1, // Ensure it is above other elements
    display: 'flex', // Ensure menuButton is centered
    alignItems: 'center',
    justifyContent: 'center',
  },
  
  menuButton: {
    backgroundColor: '#008080', // Teal color
    color: '#E0FFFF', // Light teal text
    fontWeight: 'bold',
    border: 'none', // Removed border
    borderRadius: '5px',
    cursor: 'pointer',
    padding: '0.6em 0.6em', // Adjusted padding
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    margin: '0 1em', // Add space on left and right sides
  },
  
  menuLine: {
    width: '20px', // Line width
    height: '2px', // Line height
    backgroundColor: '#E0FFFF', // Light teal color
    margin: '5px 0',
  },
  
  menuDropdown: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    backgroundColor: '#E0FFFF', // Light teal color
    border: 'none', // Removed border
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 1,
    minWidth: '100px',
    top: '100%',
    left: '0',
    padding: '0.5em',
  },
  
  menuDropdownItem: {
    padding: '0.5em 1em', // Increased padding for a larger item
    backgroundColor: '#E0FFFF', // Light teal color
    color: '#008080', // Dark teal text
    fontWeight: 'bold',
    border: 'none', // Removed border
    cursor: 'pointer',
    borderRadius: '8px', // Increased border radius for a more rounded appearance
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    margin: '0.5em 0', // Increased margin for more spacing between items
    textAlign: 'center',
    fontSize: '1.1em', // Increased font size for better readability
},
dropdownContainer: {
  position: 'relative',
  display: 'inline-block',
},
dropdown: {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  backgroundColor: '#E0FFFF', // Very light teal
  border: 'none', // Removed border
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  zIndex: 1,
  minWidth: '100px',
  top: '100%',
  left: 0,
  padding: '0.5em',
},
dropdownItem: {
  padding: '0.3em 1.5em', // Reduced padding
  backgroundColor: '#E0FFFF', // Very light teal
  color: '#008080', // Dark teal text
  fontWeight: 'bold',
  border: 'none', // Removed border
  cursor: 'pointer',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  margin: '0.5em 0',
  textAlign: 'center',
  fontSize: '1.1em', // Reduced font size
 
}
};

export default Products;
