import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './suplex-asia-logo.png'; // Import the logo image
import backgroundImage from './teal background.jpg'; // Import the background image

function Contact() {
    const [showMenu, setShowMenu] = useState(false);
    const [hoverIndex, setHoverIndex] = useState(null);
    //const [openDropdown, setOpenDropdown] = useState(null);
    const menuRef = useRef(null);
   // const dropdownRef = useRef(null);
    const navigate = useNavigate();

    const menuItems = [
        { text: 'About', action: () => navigate("/about") },
        { text: 'Products', action: () => navigate("/products") },
        { text: 'Activities', action: () => navigate(null) },
        { text: 'Buy Now', action: () => navigate(null) }
    ];

   

    const toggleMenu = () => setShowMenu(!showMenu);
    const handleMouseEnter = (index) => setHoverIndex(index);
    const handleMouseLeave = () => setHoverIndex(null);
    //const handleDropdownClick = (index) => setOpenDropdown(openDropdown === index ? null : index);

    return (
        <div style={{ ...styles.container, backgroundImage: `url(${backgroundImage})` }}>
          {/* Navigation Bar */}
          <nav style={styles.nav}>
            <ul style={styles.navList}>
              <li style={styles.navItem}>
                <img src={logo} alt="Suplex Asia Logo" style={styles.logo} /> {/* Logo Image */}
                <span style={styles.navText}>SUPLEX Ashia - Information System</span> {/* Text with common teal */}
              </li>
            </ul>
          </nav>
    
          {/* Task Bar */}
          <div style={styles.taskBar}>
            <div style={styles.menuContainer} ref={menuRef}>
              <button style={styles.menuButton} onClick={toggleMenu}>
                <div style={styles.menuLine}></div>
                <div style={styles.menuLine}></div>
                <div style={styles.menuLine}></div>
              </button>
              {showMenu && (
                <div style={styles.menuDropdown}>
                  {menuItems.map((item, index) => (
                    <button
                      key={index}
                      style={{
                        ...styles.menuDropdownItem,
                        backgroundColor: hoverIndex === index ? '#20B2AA' : '#E0FFFF' // Dark teal on hover
                      }}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      onClick={item.action} // Call the respective action
                    >
                      {item.text}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <button style={styles.button} onClick={() => navigate("/")}>HOME</button> {/* Navigate to About page */}
            <button style={styles.button} onClick={() => navigate("/products")}>PRODUCTS</button>
            <button style={styles.button} onClick={() => navigate(null)}>ACTIVITIES</button>
            <button style={styles.button} onClick={() => navigate(null)}>BuyNow</button>
            <button style={styles.button} onClick={() => navigate("/contact")}>CONTACT</button>
            <button style={styles.button} onClick={() => navigate("/login")}>Login</button>
            </div>
          <header style={styles.header}>
              <h1 style={styles.heading}>Contact Us</h1>
          </header>
          <div style={styles.contactFormContainer}>
              <form style={styles.contactForm}>
                  <label style={styles.label}>
                      Name:
                      <input type="text" name="name" style={styles.input} required />
                  </label>
                  <label style={styles.label}>
                      Email:
                      <input type="email" name="email" style={styles.input} required />
                  </label>
                  <label style={styles.label}>
                      Message:
                      <textarea name="message" style={styles.textarea} required></textarea>
                  </label>
                  <button type="submit" style={styles.submitButton}>Send</button>
              </form>
          </div>

          <footer style={styles.footer}>
              <div style={styles.footerContainer}>
                  © 2024 Suplex Ashia. All rights reserved.
              </div>
          </footer>
      </div>
    );
}

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
    background: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)', // Gradient from very light teal (70%) to dark teal
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingBottom: '50px', // Ensure space for the footer
  },
  nav: {
    backgroundColor: 'white', // White color for the nav bar
    color: 'black',
    textAlign: 'left', // Align text to the left
    width: '100%',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  navList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center', // Align items to the center vertically
  },
  navItem: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1em', // Adjust margin for spacing
  },
  logo: {
    height: '80px', // Increased height of the logo
    marginRight: '10px',
  },
  navText: {
    backgroundColor: 'transparent', // Removed background color
    color: '#008080', // Dark teal for text
    fontSize: '1.5em', // Increased font size
    fontWeight: 'bold',
  },
  taskBar: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center', // Ensure items are aligned properly
    margin: '1em 0',
    backgroundColor: '#008080',
    //padding: '0.5em 10px', // Adjusted padding
    borderRadius: '8px',
    position: 'relative',
    overflow: 'visible', // Or 'hidden' depending on needs
  },
  menuContainer: {
    position: 'absolute', // Position relative to taskBar
    left: '-60px', // Move menuButton outside of the taskBar
    top: '50%', // Center vertically
    transform: 'translateY(-50%)', // Center vertically
    zIndex: 1, // Ensure it is above other elements
  },
menuButton: {
    backgroundColor: '#008080', // Very light teal
    color: '#E0FFFF', // Dark teal text
    fontWeight: 'bold',
    border: 'none', // Removed border
    borderRadius: '5px',
    cursor: 'pointer',
    padding: '0.6em 0.6em', // Adjusted padding for better appearance
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    position: 'relative',
  },
  menuLine: {
    width: '20px', // Reduced width
    height: '2px', // Reduced height
    backgroundColor: '#E0FFFF', // Dark teal color
    margin: '2px 0',
  },
  menuDropdown: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    backgroundColor: '#E0FFFF', // Very light teal
    border: 'none', // Removed border
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 1,
    minWidth: '100px',
    top: '100%',
    left: 0,
    padding: '0.5em',
  },
  menuDropdownItem: {
    padding: '0.3em 0.6em', // Further reduced padding
    backgroundColor: '#E0FFFF', // Very light teal
    color: '#008080', // Dark teal text
    fontWeight: 'bold',
    border: 'none', // Removed border
    cursor: 'pointer',
    borderRadius: '6px', // Adjusted border radius for smaller padding
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    margin: '0.25em 0', // Reduced margin
    textAlign: 'center',
    fontSize: '0.6em', // Reduced font size
  },
  button: {
    padding: '0.5em 1.5em', // Reduced padding
    backgroundColor: '#E0FFFF', // Very light teal
    color: '#008080', // Dark teal text
    fontWeight: 'bold',
    border: 'none', // Removed border
    cursor: 'pointer',
    margin: '0.5em',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    minWidth: '50px', // Reduced min width
    flexGrow: 0,
    fontSize: '0.5em', // Reduced font size
    textAlign: 'center',
  },
  dropdownContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    backgroundColor: '#E0FFFF', // Very light teal
    border: 'none', // Removed border
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 1,
    minWidth: '100px',
    top: '100%',
    left: 0,
    padding: '0.5em',
  },
  dropdownItem: {
    padding: '0.3em 1.5em', // Reduced padding
    backgroundColor: '#E0FFFF', // Very light teal
    color: '#008080', // Dark teal text
    fontWeight: 'bold',
    border: 'none', // Removed border
    cursor: 'pointer',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    margin: '0.5em 0',
    textAlign: 'center',
    fontSize: '0.6em', // Reduced font size
  },
  header: {
    marginTop: '2em',
    textAlign: 'center',
  },
  heading: {
    color: '#008080',
    fontSize: '2em',
  },
  contactFormContainer: {
    backgroundColor: '#E0FFFF',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    margin: '2em 0',
  },
  contactForm: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#E0FFFF',
  },
  label: {
    marginBottom: '10px',
  },
  input: {
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    marginBottom: '10px',
    backgroundColor: '#E0FFFF',
  },
  textarea: {
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    marginBottom: '10px',
    minHeight: '100px',
    backgroundColor: '#E0FFFF',
  },
  submitButton: {
    backgroundColor: '#008080',
    color: '#E0FFFF',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    padding: '10px',
    fontWeight: 'bold',
  },
  footer: {
    backgroundColor: '#008080',
    color: '#E0FFFF',
    textAlign: 'center',
    padding: '10px',
    position: 'fixed',
    bottom: '0',
    width: '100%',
  },
  footerContainer: {
    maxWidth: '1200px',
    margin: '0 auto',
  },
};

export default Contact;
