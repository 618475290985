import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard';
import About from './About';
import Registration from './Registration';
import Login from './Login';
import Showname from './showname'; // Ensure the casing matches the actual file name
import UserDashboard from './userdashboard';
import { AuthProvider } from './AuthProvider'; // Import AuthProvider
import PersonalDash from './PersonalDash';
import SalesReport from './SalesReport';
import CustomerRegistrationForm from './CustomerRegistrationForm';
import Products from './products';
import TermsAndConditions from './TermsAndConditions';
import SendMail from './SendMail';
import Contact from './contact';
import Activities from './Activities';
import BuyNow from './BuyNow';
function App() {
  return (
    <Router>
      <AuthProvider> {/* Wrap your routes with AuthProvider */}
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/about" element={<About />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/login" element={<Login />} />
          <Route path="/userdashboard" element={<UserDashboard />} />
          <Route path="/showname" element={<Showname />} />
          <Route path="/personaldash" element={<PersonalDash/>} />
          <Route path="/salesreport" element={<SalesReport/>} />
          <Route path="/CustomerRegistrationForm" element={<CustomerRegistrationForm/>} />
          <Route path="/products" element={<Products/>} />
          <Route path="/termsandconditions" element={<TermsAndConditions/>} />
          <Route path="/sendmail" element={<SendMail />} />
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/activities" element={<Activities/>}/>
          <Route path="/buynow" element={<BuyNow/>}/>
          
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
