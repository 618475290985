import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './suplex-asia-logo.png'; // Import the logo image
import backgroundImage from './teal background.jpg'; // Import the background image
import cosmetic1 from './cosmetic1.jpg'; // Import the first cosmetic image
import cosmetic2 from './cosmetic2.jpg'; // Import the second cosmetic image
import cosmetic3 from './cosmetic3.jpg'; // Import the third cosmetic image
import cosmetic4 from './cosmetic4.jpg'; // Import the fourth cosmetic image
import cosmetic5 from './cosmetic5.jpg'; // Import the fifth cosmetic image

function Dashboard() {
  const navigate = useNavigate();
  const [hoverIndex, setHoverIndex] = useState(null);
  const [showMenu, setShowMenu] = useState(false); // State for the hamburger menu
  const menuRef = useRef(null); // Ref for the menu

  // Toggle dropdown visibility
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  // Close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  // Define action handlers for menu items
  const handleAbout = () => {
    navigate('/about'); // Navigate to About page
  };

  const handleProducts = () => {
    console.log("Products action");
  };

  const handlePromotionalPath = () => {
    console.log("Promotional Path action");
  };

  const handleContact = () => {
    console.log("Contact action");
  };

  const handleLogin = () => {
    navigate('/login'); // Navigate to Login page
  };

  const menuItems = [
    { text: "About", action: handleAbout },
    { text: "Products", action: handleProducts },
    { text: "Promotional Path", action: handlePromotionalPath },
    { text: "Contact", action: handleContact },
    { text: "Login", action: handleLogin }
  ];

  return (
    <div style={styles.wrapper}>
      {/* Background Container */}
      <div style={styles.background} />

      {/* Navigation Bar */}
      <div style={styles.nav}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={logo} alt="Logo" style={{ height: '70px', marginRight: '10px' }} />
                        <span style={styles.navText}>SUPLEX Ashia</span> {/* Text with common teal */}
                        <span style={{color: 'teal', fontSize: '1.5em', fontWeight: 'bold',alignItems: 'center',paddingLeft:'10px'}}>InformationSystem</span> {/* Text with common teal */}
                    </div>
                    </div>
      {/* Task Bar */}
      <div style={styles.taskBar}>
        <div style={styles.menuContainer} ref={menuRef}>
          <button style={styles.menuButton} onClick={toggleMenu}>
            <div style={styles.menuLine}></div>
            <div style={styles.menuLine}></div>
            <div style={styles.menuLine}></div>
          </button>
          {showMenu && (
            <div style={styles.menuDropdown}>
              {menuItems.map((item, index) => (
                <button
                  key={index}
                  style={{
                    ...styles.menuDropdownItem,
                    backgroundColor: hoverIndex === index ? '#20B2AA' : '#E0FFFF' // Dark teal on hover
                  }}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onClick={item.action} // Call the respective action
                >
                  {item.text}
                </button>
              ))}
            </div>
          )}
        </div>
        <button style={styles.button} onClick={() => navigate("/")}>HOME</button> {/* Navigate to Home page */}
        <button style={styles.button} onClick={() => navigate("/products")}>PRODUCTS</button>
        <button style={styles.button} onClick={() => navigate("/activities")}>ACTIVITIES</button>
        <button style={styles.button} onClick={() => navigate("/buynow")}>BUY NOW</button>
        <button style={styles.button} onClick={() => navigate("/contact")}>CONTACT</button>
      </div>

      {/* Images Section */}
      <div style={styles.imageSection}>
        <div style={styles.imageWrapper}>
          <img src={cosmetic1} alt="Cosmetic 1" style={styles.image} />
          <img src={cosmetic2} alt="Cosmetic 2" style={styles.image} />
          <img src={cosmetic3} alt="Cosmetic 3" style={styles.image} />
          <img src={cosmetic4} alt="Cosmetic 4" style={styles.image} />
          <img src={cosmetic5} alt="Cosmetic 5" style={styles.image} />
        </div>
      </div>

      {/* Footer */}
      <footer style={styles.footer}>
        <div style={styles.footerContainer}>
          <p>© 2024 Suplex Ashia. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
    background: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)', // Gradient from light teal to dark teal
    //minHeight: '100vh', // Ensure the container covers the full viewport height
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', // Distribute content evenly
    alignItems: 'center', // Center horizontally
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingBottom: '50px', // Space for any footer if needed
    width: '1620px', // Fixed width to enforce desktop view
    overflowX: 'hidden', // Prevent horizontal scrollbars
  },
  
  nav: {
    display: 'flex',
    justifyContent: 'space-between', // Space out the title and button
    alignItems: 'center',
    backgroundColor: '#FFFAFA', // White color for the nav bar
    color: 'black',
    height: '120px',
    //padding: '0.5em', // Reduced padding
    width: '100%',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  navList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center', // Align items to the center vertically
  },
  navItem: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1em', // Adjust margin for spacing
  },
  logo: {
    height: '80px', // Increased height of the logo
    marginRight: '10px',
  },
  navText: {
    backgroundColor: 'transparent', // Removed background color
    color: '#008080', // Dark teal for text
    fontSize: '1.7em', // Default font size
    fontWeight: 'bold',
    // Media query for tablets and small devices
    '@media (max-width: 768px)': {
      fontSize: '1.2em', // Smaller font size for tablets
    },
    // Media query for mobile devices
    '@media (max-width: 480px)': {
      fontSize: '0.1em', // Smaller font size for mobile phones
    },
  },
  wrapper: {
    fontFamily: 'Arial, sans-serif',
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
    position: 'relative', // Position relative to contain absolutely positioned children
    minHeight: '100vh', // Ensure wrapper covers the full viewport height
    background: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)', // Gradient from light teal to dark teal
},
taskBar: {
  display: 'flex',
  justifyContent: 'center', // Center items horizontally
  alignItems: 'center', // Center items vertically
  margin: '1em 0',
  backgroundColor: '#008080', // Teal color for the task bar
  padding: '0.5em', // Reduced padding
  borderRadius: '8px',
  flexWrap: 'wrap', // Allow items to wrap on smaller screens
  position: 'relative', // Ensure positioning context for children
},

menuContainer: {
  position: 'absolute', // Position relative to taskBar
  left: '1em', // Add space from the left edge of taskBar
  top: '50%', // Center vertically
  transform: 'translateY(-50%)', // Center vertically
  zIndex: 1, // Ensure it is above other elements
  display: 'flex', // Ensure menuButton is centered
  alignItems: 'center',
  justifyContent: 'center',
},

menuButton: {
  backgroundColor: '#008080', // Teal color
  color: '#E0FFFF', // Light teal text
  fontWeight: 'bold',
  border: 'none', // Removed border
  borderRadius: '5px',
  cursor: 'pointer',
  padding: '0.6em 0.6em', // Adjusted padding
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  position: 'relative',
  margin: '0 1em', // Add space on left and right sides
},

menuLine: {
  width: '20px', // Line width
  height: '2px', // Line height
  backgroundColor: '#E0FFFF', // Light teal color
  margin: '2px 0',
},

menuDropdown: {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  backgroundColor: '#E0FFFF', // Light teal color
  border: 'none', // Removed border
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  zIndex: 1,
  minWidth: '100px',
  top: '100%',
  left: '0',
  padding: '0.5em',
},

menuDropdownItem: {
  padding: '0.3em 0.6em', // Padding
  backgroundColor: '#E0FFFF', // Light teal color
  color: '#008080', // Dark teal text
  fontWeight: 'bold',
  border: 'none', // Removed border
  cursor: 'pointer',
  borderRadius: '6px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  margin: '0.25em 0', // Margin
  textAlign: 'center',
  fontSize: '0.9em', // Font size
},

  button: {
    padding: '0.5em 1.5em', // Reduced padding
    backgroundColor: '#E0FFFF', // Very light teal
    color: '#008080', // Dark teal text
    fontWeight: 'bold',
    border: 'none', // Removed border
    cursor: 'pointer',
    margin: '0.5em',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    minWidth: '50px', // Reduced min width
    fontSize: '0.9em', // Reduced font size
    textAlign: 'center',
  },
  dropdownContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    backgroundColor: '#E0FFFF', // Very light teal
    border: 'none', // Removed border
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 1,
    minWidth: '100px',
    top: '100%',
    left: 0,
    padding: '0.5em',
  },
  dropdownItem: {
    padding: '0.3em 1.5em', // Reduced padding
    backgroundColor: '#E0FFFF', // Very light teal
    color: '#008080', // Dark teal text
    fontWeight: 'bold',
    border: 'none', // Removed border
    cursor: 'pointer',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    margin: '0.5em 0',
    textAlign: 'center',
    fontSize: '0.9em', // Reduced font size
   
  },
  imageSection: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    height: '250px',
  },
  imageWrapper: {
    display: 'flex',
    animation: 'scroll 10s linear infinite',
    height: '100%',
  },
  image: {
    flexShrink: 0,
    width: '100%',
    height: '2000px',
    objectFit: 'cover',
    margin: '10px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  footer: {
    backgroundColor: '#008080',
    color: '#fff',
    textAlign: 'center',
    padding: '10px',
    width: '100%',
    position: 'fixed',
    bottom: 0,
},
  footerContainer: {
    margin: '0 auto',
    width: '80%',
},
};

// Define keyframes for scrolling animation
const styleSheet = document.styleSheets[0];
const keyframes = `
@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}
`;
styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

export default Dashboard;