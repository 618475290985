// firebaseConfig.js
import { initializeApp, getApp, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyCrPGno16NssvcajaovZkt3-HPuBOqN0qk",
    authDomain: "suplexashia.firebaseapp.com",
    projectId: "suplexashia",
    storageBucket: "suplexashia.appspot.com",
    messagingSenderId: "359629003094",
    appId: "1:359629003094:web:75012dfe8cd92855aa1ef7",
    measurementId: "G-14D6X8SCK2"
};

// Check if Firebase app is already initialized
const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
const auth = getAuth(app);
const database = getDatabase(app);

export { auth, database };
