// AuthProvider.jsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase'; // Ensure you export auth from firebase.js

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                // Fetch the user's placementTop value from Firebase or set a placeholder
                const placementTop = "ExamplePlacementTop"; // Fetch this from Firebase or your user data
                setUser({
                    uid: user.uid,
                    email: user.email,
                    placementTop: placementTop
                });
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <AuthContext.Provider value={{ user }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}
