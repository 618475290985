import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './suplex-asia-logo.png'; // Import the logo image
function Activities() {
    const navigate = useNavigate();

    return (
        <div style={styles.wrapper}>
        {/* Background Container */}
        <div style={styles.background} />
            {/* Navigation Bar */}
            <nav style={styles.nav}>
                <ul style={styles.navList}>
                    <li style={styles.navItem}>
                        <img src={logo} alt="Suplex Asia Logo" style={styles.logo} /> {/* Logo Image */}
                        <span style={styles.navText}>SUPLEX Ashia - Information System</span> {/* Text with common teal */}
                    </li>
                </ul>
            </nav>

            {/* Task Bar */}
            <div style={styles.taskBar}>
                <button style={styles.button} onClick={() => navigate("/")}>HOME</button>
                <button style={styles.button} onClick={() => navigate("/products")}>PRODUCTS</button>
                <button style={styles.button} onClick={() => navigate("/activities")}>ACTIVITIES</button>
                <button style={styles.button} onClick={() => navigate("/buynow")}>BuyNow</button>
                <button style={styles.button} onClick={() => navigate("/login")}>Login</button>
            </div>

            {/* Main Content */}
            <header style={styles.header}>
                <h1 style={styles.heading}>Under Development</h1>
                <p style={styles.message}>This section is currently under development. Stay tuned for updates!</p>
            </header>

            <footer style={styles.footer}>
                <div style={styles.footerContainer}>
                    © 2024 Suplex Ashia. All rights reserved.
                </div>
            </footer>
        </div>
    );
}

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        background: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)', // Gradient from light teal to dark teal
        //minHeight: '100vh', // Ensure the container covers the full viewport height
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Distribute content evenly
        alignItems: 'center', // Center horizontally
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        paddingBottom: '50px', // Space for any footer if needed
        width: '1620px', // Fixed width to enforce desktop view
        overflowX: 'hidden', // Prevent horizontal scrollbars
      },
      
      nav: {
        display: 'flex',
        justifyContent: 'space-between', // Space out the title and button
        alignItems: 'center',
        backgroundColor: '#FFFAFA', // White color for the nav bar
        color: 'black',
        height: '120px',
        //padding: '0.5em', // Reduced padding
        width: '100%',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      },
      navList: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        display: 'flex',
        alignItems: 'center', // Align items to the center vertically
      },
      navItem: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '1em', // Adjust margin for spacing
      },
      logo: {
        height: '80px', // Increased height of the logo
        marginRight: '10px',
      },
      navText: {
        backgroundColor: 'transparent', // Removed background color
        color: '#008080', // Dark teal for text
        fontSize: '1.7em', // Default font size
        fontWeight: 'bold',
        // Media query for tablets and small devices
        '@media (max-width: 768px)': {
          fontSize: '1.2em', // Smaller font size for tablets
        },
        // Media query for mobile devices
        '@media (max-width: 480px)': {
          fontSize: '0.1em', // Smaller font size for mobile phones
        },
      },
      wrapper: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'relative', // Position relative to contain absolutely positioned children
        minHeight: '100vh', // Ensure wrapper covers the full viewport height
        background: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)', // Gradient from light teal to dark teal
    },
    taskBar: {
      display: 'flex',
      justifyContent: 'center', // Center items horizontally
      alignItems: 'center', // Center items vertically
      margin: '1em 0',
      backgroundColor: '#008080', // Teal color for the task bar
      padding: '0.5em', // Reduced padding
      borderRadius: '8px',
      flexWrap: 'wrap', // Allow items to wrap on smaller screens
      position: 'relative', // Ensure positioning context for children
    },
    
    menuContainer: {
      position: 'absolute', // Position relative to taskBar
      left: '1em', // Add space from the left edge of taskBar
      top: '50%', // Center vertically
      transform: 'translateY(-50%)', // Center vertically
      zIndex: 1, // Ensure it is above other elements
      display: 'flex', // Ensure menuButton is centered
      alignItems: 'center',
      justifyContent: 'center',
    },
    
    menuButton: {
      backgroundColor: '#008080', // Teal color
      color: '#E0FFFF', // Light teal text
      fontWeight: 'bold',
      border: 'none', // Removed border
      borderRadius: '5px',
      cursor: 'pointer',
      padding: '0.6em 0.6em', // Adjusted padding
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      position: 'relative',
      margin: '0 1em', // Add space on left and right sides
    },
    
    menuLine: {
      width: '20px', // Line width
      height: '2px', // Line height
      backgroundColor: '#E0FFFF', // Light teal color
      margin: '2px 0',
    },
    
    menuDropdown: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      backgroundColor: '#E0FFFF', // Light teal color
      border: 'none', // Removed border
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      zIndex: 1,
      minWidth: '100px',
      top: '100%',
      left: '0',
      padding: '0.5em',
    },
    
    menuDropdownItem: {
      padding: '0.3em 0.6em', // Padding
      backgroundColor: '#E0FFFF', // Light teal color
      color: '#008080', // Dark teal text
      fontWeight: 'bold',
      border: 'none', // Removed border
      cursor: 'pointer',
      borderRadius: '6px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      margin: '0.25em 0', // Margin
      textAlign: 'center',
      fontSize: '0.9em', // Font size
    },
    
      button: {
        padding: '0.5em 1.5em', // Reduced padding
        backgroundColor: '#E0FFFF', // Very light teal
        color: '#008080', // Dark teal text
        fontWeight: 'bold',
        border: 'none', // Removed border
        cursor: 'pointer',
        margin: '0.5em',
        borderRadius: '5px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        minWidth: '50px', // Reduced min width
        fontSize: '0.9em', // Reduced font size
        textAlign: 'center',
      },
      dropdownContainer: {
        position: 'relative',
        display: 'inline-block',
      },
      dropdown: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        backgroundColor: '#E0FFFF', // Very light teal
        border: 'none', // Removed border
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        zIndex: 1,
        minWidth: '100px',
        top: '100%',
        left: 0,
        padding: '0.5em',
      },
      dropdownItem: {
        padding: '0.3em 1.5em', // Reduced padding
        backgroundColor: '#E0FFFF', // Very light teal
        color: '#008080', // Dark teal text
        fontWeight: 'bold',
        border: 'none', // Removed border
        cursor: 'pointer',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        margin: '0.5em 0',
        textAlign: 'center',
        fontSize: '0.9em', // Reduced font size
       
      },
    header: {
        marginTop: '2em',
        textAlign: 'center',
    },
    heading: {
        color: '#008080',
        fontSize: '2em',
    },
    message: {
        color: '#008080',
        fontSize: '1.2em',
        marginTop: '0.5em',
    },
    footer: {
        backgroundColor: '#008080',
        color: '#E0FFFF',
        textAlign: 'center',
        padding: '10px',
        position: 'fixed',
        bottom: '0',
        width: '100%',
    },
    footerContainer: {
        maxWidth: '1200px',
        margin: '0 auto',
    },
};

export default Activities;
