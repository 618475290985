import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './suplex-asia-logo.png';
import { ref, get, child } from 'firebase/database';
import { database } from './firebaseConfig'; // Ensure the path is correct

const CustomerRegistrationForm = () => {
  const [placementTop, setPlacementTop] = useState('SRI'); // Initialize with 'SRI'
  const [displayedPlacementTop, setDisplayedPlacementTop] = useState(''); // For input display
  const [error, setError] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(null);
  const navigate = useNavigate();

  const handleSelectManager = () => {
    if (displayedPlacementTop) {
      const dbRef = ref(database);

      // Fetch all users from the database
      get(child(dbRef, 'users')).then((snapshot) => {
        if (snapshot.exists()) {
          const users = snapshot.val();
          const user = Object.values(users).find(user => user.placementTop === placementTop);

          if (user) {
            // If the placementTop exists, navigate to the registration page with the name and placementTop
            navigate('/registration', { state: { placementTop: user.placementTop, userName: user.name } });
            setError('');
          } else {
            // If not, show an error message
            setError('No user found with the provided Placement Top.');
          }
        } else {
          setError('No data found in the database.');
        }
      }).catch((error) => {
        console.error("Error checking placementTop:", error);
        setError('An error occurred while checking the Placement Top.');
      });
    } else {
      setError('Please enter the Placement Top.');
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  const handleInputChange = (e) => {
    setDisplayedPlacementTop(e.target.value);
    // Update placementTop with the prefix "SRI" only if it's not already there
    setPlacementTop('SRI' + e.target.value.replace(/^SRI/, ''));
  };

  const dropdownItems = ['Item 1', 'Item 2', 'Item 3'];

  return (
    <div style={styles.wrapper}>
            {/* Background Container */}
            <div style={styles.background} />
      <nav style={styles.nav}>
        <ul style={styles.navList}>
          <li style={{ ...styles.navItem, ...styles.navBrand }}>
            <img src={logo} alt="Suplex Asia Logo" style={styles.logo} />
            <span style={styles.navText}>SUPLEX Asia - Information System</span>
          </li>
        </ul>
        <div style={{ display: 'flex', flexDirection: 'column',alignItems: 'flex-end', paddingRight: '20px' }}>
                    <button style={{ marginTop: '2px', padding: '12px', backgroundColor: '#20B2AA', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }} onClick={() => navigate("/")}>Logout</button>

                    </div>
      </nav>

      <div style={styles.taskBar}>
        <button style={styles.button} onClick={() => navigate("/")}>HOME</button>
        <button style={styles.button}>SALES REPORT</button>
        <button style={styles.button}>EDUCATION</button>
        <button style={styles.button}>COMMISION HISTORY</button>
        <button style={styles.button}>CUSTOMER REGISTRATION</button>
        <button style={styles.button}>G6</button>
        <div style={styles.dropdownContainer}>
          <button style={styles.button} onClick={toggleDropdown}>SPECIAL TASKS</button>
          {showDropdown && (
            <div style={styles.dropdown}>
              {dropdownItems.map((item, index) => (
                <button
                  key={index}
                  style={{
                    ...styles.dropdownItem,
                    backgroundColor: hoverIndex === index ? '#20B2AA' : '#f0f0f0'
                  }}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  {item}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      <div style={styles.formContainer}>
        <header style={{ textAlign: 'right', marginBottom: '20px' }}>
          <h2 style={{ color: '#000000', fontWeight: 'normal' }}>Customer Registration Form</h2>
        </header>

        <section style={{ marginBottom: '20px' }}>
          <h4 style={{ color: '#000000', marginBottom: '10px' }}>
            Step 1 of 4: Select Team Manager
          </h4>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <label style={{ marginRight: '20px', color: '#999999' }}>USER ID</label>
            <input
              type="text"
              value={displayedPlacementTop}
              onChange={handleInputChange}
              style={{
                width: '100%', // Make input fields span the available width
                maxWidth: '300px', // Limit the maximum width
                padding: '6px', // Padding inside the input
                borderRadius: '4px', // Rounded corners for the input
                border: '1px solid #008080', // Border color teal
                boxSizing: 'border-box', // Proper box sizing to include padding and border in width
                backgroundColor: '#E0FFFF', // Very light teal background
                fontSize: '40px', // Prevent zooming by setting font size to 16px
              }}
            />
            <button
              onClick={handleSelectManager}
              style={{
                padding: '0.5em 1.5em', // Reduced padding
                backgroundColor: '#008080', // Very light teal
                color: '#E0FFFF', // Dark teal text
                fontWeight: 'bold',
                border: 'none', // Removed border
                cursor: 'pointer',
                margin: '0.5em',
                borderRadius: '5px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                minWidth: '50px', // Reduced min width
                fontSize: '0.8em', // Reduced font size
                textAlign: 'center',
              }}
            >
              SELECT TEAM MANAGER
            </button>
            <button
              onClick={() => {
                setDisplayedPlacementTop('');
                setPlacementTop('SRI');
              }}
              style={{
                padding: '0.5em 1.5em', // Reduced padding
                backgroundColor: '#008080', // Very light teal
                color: '#E0FFFF', // Dark teal text
                fontWeight: 'bold',
                border: 'none', // Removed border
                cursor: 'pointer',
                margin: '0.5em',
                borderRadius: '5px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                minWidth: '50px', // Reduced min width
                fontSize: '0.8em', // Reduced font size
                textAlign: 'center',
              }}
            >
              Reset
            </button>
          </div>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </section>
      </div>
      <footer style={styles.footer}>
                <div style={styles.footerContainer}>
                    <p>© 2024 Suplex Asia PVT (LTD). All rights reserved.</p>
                </div>
            </footer>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
    background: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)', // Gradient from light teal to dark teal
    //minHeight: '100vh', // Ensure the container covers the full viewport height
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', // Distribute content evenly
    alignItems: 'center', // Center horizontally
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingBottom: '50px', // Space for any footer if needed
    width: '1620px', // Fixed width to enforce desktop view
    overflowX: 'hidden', // Prevent horizontal scrollbars
  },
  
  nav: {
    display: 'flex',
    justifyContent: 'space-between', // Space out the title and button
    alignItems: 'center',
    backgroundColor: '#FFFAFA', // White color for the nav bar
    height:'120px',
    color: 'black',
    //padding: '0.5em', // Reduced padding
    width: '100%',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  navList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center', // Align items to the center vertically
  },
  navItem: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1em', // Adjust margin for spacing
  },
  logo: {
    height: '80px', // Increased height of the logo
    marginRight: '10px',
  },
  navText: {
    backgroundColor: 'transparent', // Removed background color
    color: '#008080', // Dark teal for text
    fontSize: '1.5em', // Default font size
    fontWeight: 'bold',
    // Media query for tablets and small devices
    '@media (max-width: 768px)': {
      fontSize: '1.2em', // Smaller font size for tablets
    },
    // Media query for mobile devices
    '@media (max-width: 480px)': {
      fontSize: '0.1em', // Smaller font size for mobile phones
    },
  },
  wrapper: {
    fontFamily: 'Arial, sans-serif',
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
    position: 'relative', // Position relative to contain absolutely positioned children
    minHeight: '100vh', // Ensure wrapper covers the full viewport height
    background: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)', // Gradient from light teal to dark teal
},
  taskBar: {
    display: 'flex',
    justifyContent: 'flex-start', // Align items to the start (left)
    margin: '1em 0',
    backgroundColor: '#008080', // Teal color for the task bar
    padding: '0.5em', // Reduced padding
    borderRadius: '8px',
    flexWrap: 'wrap', // Allow items to wrap on smaller screens
    position: 'relative', // Ensure positioning context for children
    paddingLeft: '10px', // Ensure space for the menu button
     justifyContent: 'center',
    alignItems: 'center'
  },
  menuContainer: {
    position: 'absolute', // Position relative to taskBar
    left: '-60px', // Move menuButton outside of the taskBar
    top: '50%', // Center vertically
    transform: 'translateY(-50%)', // Center vertically
    zIndex: 1, // Ensure it is above other elements
  },
  menuButton: {
    backgroundColor: '#008080', // Very light teal
    color: '#E0FFFF', // Dark teal text
    fontWeight: 'bold',
    border: 'none', // Removed border
    borderRadius: '5px',
    cursor: 'pointer',
    padding: '0.6em 0.6em', // Adjusted padding for better appearance
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    position: 'relative',
  },
  menuLine: {
    width: '20px', // Reduced width
    height: '2px', // Reduced height
    backgroundColor: '#E0FFFF', // Dark teal color
    margin: '2px 0',
  },
  menuDropdown: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    backgroundColor: '#E0FFFF', // Very light teal
    border: 'none', // Removed border
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 1,
    minWidth: '100px',
    top: '100%',
    left: 0,
    padding: '0.5em',
  },
  menuDropdownItem: {
    padding: '0.3em 0.6em', // Further reduced padding
    backgroundColor: '#E0FFFF', // Very light teal
    color: '#008080', // Dark teal text
    fontWeight: 'bold',
    border: 'none', // Removed border
    cursor: 'pointer',
    borderRadius: '6px', // Adjusted border radius for smaller padding
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    margin: '0.25em 0', // Reduced margin
    textAlign: 'center',
    fontSize: '0.6em', // Reduced font size
  },
  button: {
    padding: '0.5em 1.5em', // Reduced padding
    backgroundColor: '#E0FFFF', // Very light teal
    color: '#008080', // Dark teal text
    fontWeight: 'bold',
    border: 'none', // Removed border
    cursor: 'pointer',
    margin: '0.5em',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    minWidth: '50px', // Reduced min width
    fontSize: '0.9em', // Reduced font size
    textAlign: 'center',
  },
  dropdownContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    backgroundColor: '#E0FFFF', // Very light teal
    border: 'none', // Removed border
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 1,
    minWidth: '100px',
    top: '100%',
    left: 0,
    padding: '0.5em',
  },
  dropdownItem: {
    padding: '0.3em 1.5em', // Reduced padding
    backgroundColor: '#E0FFFF', // Very light teal
    color: '#008080', // Dark teal text
    fontWeight: 'bold',
    border: 'none', // Removed border
    cursor: 'pointer',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    margin: '0.5em 0',
    textAlign: 'center',
    fontSize: '0.9em', // Reduced font size
   
  },
  formContainer: {
    display: 'flex',
        flexDirection: 'column',
        maxWidth: '1200px', // Set reasonable max width
        width: '100%', // Allow form to scale with screen size
        margin: '20px auto', // Center form
        backgroundColor: '#e0f2f1',
        padding: '20px', // Adjust padding for smaller screens
        borderRadius: '12px', // Rounded corners
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)', // Shadow for depth
        boxSizing: 'border-box',
        position: 'relative',
        top: '10px',
        width: '900px', // Fixed width to enforce desktop view
        marginBottom: '50px', // Ensures form bottom doesn't touch the footer
},
  footer: {
    backgroundColor: '#008080',
    color: '#fff',
    textAlign: 'center',
    padding: '10px',
    width: '100%',
    position: 'fixed',
    bottom: 0,
},
  footerContainer: {
    margin: '0 auto',
    width: '80%',
},
};

export default CustomerRegistrationForm;
