import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, get } from 'firebase/database';

const database = getDatabase();

function Login() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const userRef = ref(database, 'users/');
            const snapshot = await get(userRef);

            if (snapshot.exists()) {
                const users = snapshot.val();
                const user = Object.values(users).find(user => user.placementTop === username);

                if (user) {
                    if (user.password === password) {
                        navigate('/personaldash', { state: { placementTop: username } });
                    } else {
                        setError('Incorrect password.');
                    }
                } else {
                    setError('Username not found.');
                }
            } else {
                setError('No users found.');
            }
        } catch (error) {
            console.error('Error during login:', error);
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <div style={styles.wrapper}>
            <div style={styles.background} />
            <div style={styles.mainContent}>
                <form onSubmit={handleSubmit} style={styles.form}>
                    <h2 style={styles.heading}>Login Form</h2>
                    <div style={styles.formGroup}>
                        <label htmlFor="username" style={styles.label}>User ID:</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            style={styles.input}
                            placeholder=""
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="password" style={styles.label}>Password:</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={styles.input}
                            placeholder=""
                        />
                    </div>
                    {error && <p style={styles.error}>{error}</p>}
                    <div style={styles.formGroup}>
                        <button type="submit" style={styles.button}>Login</button>
                    </div>
                </form>
            </div>
            <footer style={styles.footer}>
                <div style={styles.footerContainer}>
                    © 2024 Suplex Ashia. All rights reserved.
                </div>
            </footer>
        </div>
    );
}

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        background: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)', // Gradient from light teal to dark teal
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Distribute content evenly
        alignItems: 'center', // Center horizontally
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        paddingBottom: '50px', // Space for any footer if needed
        width: '1620px', // Fixed width to enforce desktop view
        overflowX: 'hidden', // Prevent horizontal scrollbars
    },
    wrapper: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'relative', // Position relative to contain absolutely positioned children
        minHeight: '100vh', // Ensure wrapper covers the full viewport height
        background: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)', // Gradient from light teal to dark teal
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '1200px', // Set reasonable max width
        width: '100%', // Allow form to scale with screen size
        margin: '20px auto', // Center form
        backgroundColor: '#e0f2f1',
        padding: '20px', // Adjust padding for smaller screens
        borderRadius: '12px', // Rounded corners
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)', // Shadow for depth
        boxSizing: 'border-box',
        position: 'relative',
        top: '10px',
        width: '500px', // Fixed width to enforce desktop view
        marginBottom: '50px', // Ensures form bottom doesn't touch the footer
    },
    formGroup: {
        marginBottom: '50px', // Consistent spacing between form fields
    },
    label: {
        marginBottom: '5px',
        fontWeight: 'bold',
        color: 'teal',
    },
    input: {
        width: '100%', // Make input fields span the available width
        maxWidth: '500px', // Limit the maximum width
        padding: '6px', // Padding inside the input
        borderRadius: '4px', // Rounded corners for the input
        border: '1px solid #008080', // Border color teal
        boxSizing: 'border-box', // Proper box sizing to include padding and border in width
        backgroundColor: '#E0FFFF', // Very light teal background
        fontSize: '40px', // Prevent zooming by setting font size to 16px
    },
    button: {
        margin: '10px 0',
        padding: '10px 20px',
        backgroundColor: 'teal',
        color: '#E0FFFF',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '5px',
        width: '40%',
        transform: 'translate(1%, -50%)', // Centers the form vertically and horizontally
    },
    error: {
        color: 'red',
        marginTop: '10px',
        textAlign: 'center',
    },
    footer: {
        backgroundColor: '#008080',
        color: '#fff',
        textAlign: 'center',
        padding: '10px',
        width: '100%',
        position: 'fixed',
        bottom: 0,
    },
    footerContainer: {
        margin: '0 auto',
        width: '80%',
    },
    heading: {
        fontSize: '1.5em',
        textAlign: 'center',
        color: 'teal',
    },
};
export default Login;
