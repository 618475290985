import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './suplex-asia-logo.png';
// Define or import your variables here
const dropdownItems = ['Item 1', 'Item 2', 'Item 3']; // Replace with your dropdown items

function TermsAndConditions() {
    const [isChecked, setIsChecked] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [hoverIndex, setHoverIndex] = useState(null);
    const navigate = useNavigate();

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    
    const handleAllowClick = () => {
        if (isChecked) {
            navigate('/CustomerRegistrationForm');
        } else {
            alert('You must accept the terms and conditions to proceed.');
        }
    };
    
    
    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleMouseEnter = (index) => {
        setHoverIndex(index);
    };

    const handleMouseLeave = () => {
        setHoverIndex(null);
    };

    return (
        <div style={styles.Bwrapper}>
      {/* Background Container */}
      <div style={styles.background} />
            <nav style={styles.nav}>
                <ul style={styles.navList}>
                    <li style={styles.navItem}>
                        <img src={logo} alt="Suplex Asia Logo" style={styles.logo} />
                        <span style={styles.navText}>SUPLEX Asia - Information System</span>
                    </li>
                </ul>
                <div style={{ display: 'flex', flexDirection: 'column',alignItems: 'flex-end', paddingRight: '20px' }}>
                    <button style={{ marginTop: '2px', padding: '12px', backgroundColor: '#20B2AA', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }} onClick={() => navigate("/")}>Logout</button>

                    </div>
            </nav>
            

            <div style={styles.taskBar}>
                <button style={styles.button} onClick={() => navigate("/")}>HOME</button>
                <button style={styles.button} >SALES REPORT</button>
                <button style={styles.button} >EDUCATION</button>
                <button style={styles.button} >COMMISION HISTORY</button>
                <button style={styles.button} onClick={() => navigate("/termsandconditions")}>CUSTOMER REGISTRATION</button>
                <button style={styles.button}>G6</button>
                <div style={styles.dropdownContainer}>
                    <button style={styles.button} onClick={toggleDropdown}>SPECIAL TASKS</button>
                    {showDropdown && (
                        <div style={styles.dropdown}>
                            {dropdownItems.map((item, index) => (
                                <button
                                    key={index}
                                    style={{
                                        ...styles.dropdownItem,
                                        backgroundColor: hoverIndex === index ? '#20B2AA' : '#E0FFFF'
                                    }}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    {item}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <div style={styles.wrapper}>
                <div style={styles.form}>
                    <h2 style={styles.header}>Terms and Conditions</h2>
                    <p style={styles.text}>
                        Please read these terms and conditions carefully before using our service. By using our service, you agree to be bound by these terms. If you do not agree to these terms, you are not authorized to use our service.
                    </p>
                    <p style={styles.text}>
                        <strong>Independent Marketing Distributer Agreement</strong><br /><br />

                        I am an Independent marketing distributer of Suplex Ashia PVT (LTD). I understand the terms of this agreement and state the following facts willingly:
                        <ol>
                            <li>I am above eighteen years (18) of age.</li>
                            <li>I have requested to act as an independent marketing distributer of Suplex Ashia PVT (LTD).</li>
                            <li>I agree to follow all the methodologies, policies and rules and regulations stated by the company at present and any amendments in the future.</li>
                            <li>I act as an independent marketing distributer of Suplex Ashia PVT (LTD), not as an employee or a representative while also being aware that spreading false information which isn't included in the handbook provided by the company is wrong.</li>
                            <li>I promise to present the profit accounts and records on the profits earned by sales through Suplex Ashia PVT (LTD) to the Department of Income Tax and other authorized agencies while paying all the taxes and payments within the required time period.</li>
                            <li>I promise to market the products of Suplex Ashia PVT (LTD) with responsibility and dedication while not spreading false information or exaggerations.</li>
                            <li>I will refrain from violating rules and regulations, provisions, policies, and operations of Suplex Ashia PVT (LTD) while understanding that violation of this rule may result in me losing my independent marketing distributorship.</li>
                            <li>I will follow the guidelines set by Suplex Ashia PVT (LTD) when selling, informing, and introducing new items to customers.</li>
                            <li>I am aware that products I sell to customers through Suplex Ashia PVT (LTD) as an independent marketing distributer must be delivered within 7 days of purchase.</li>
                            <li>I will not promote goods of other direct selling companies while my independent marketing distributorship in Suplex Ashia PVT (LTD) is active.</li>
                            <li>I am aware that it is compulsory to attend the promotional counseling programs conducted by the company to obtain the knowledge and information on the products and services offered by the company.</li>
                            <li>I will act according to the Arbitration Act No. 11 in case there is any confusion between me and the company. I also understand that the decision given by the Sri Lanka arbitration center is valid for both parties.</li>
                            <li>I am aware that failing to deposit the money received from customers for the sales done by me through Suplex Ashia PVT (LTD) within two working days will result in cancellation of my independent marketing distributorship and that doing so can tarnish my good name within the company. I am aware that the right to cancel my independent marketing distributorship will be given to Suplex Ashia PVT LTD right to the clauses mentioned above and any of the clauses to be introduced in the future are violated.</li>
                        </ol>
                        <br />
                    </p>
                    <div style={styles.checkboxContainer}>
                        <input
                            type="checkbox"
                            id="termsCheckbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            style={styles.checkbox}
                        />
                        <label htmlFor="termsCheckbox" style={styles.label}>I agree to the Terms and Conditions</label>
                    </div>
                    <button
                        onClick={handleAllowClick}
                        style={{ ...styles.allowbutton, backgroundColor: isChecked ? '#FFFFFF' : '#9E9E9E' }}
                        disabled={!isChecked}
                    >
                        Allow
                    </button>
                </div>
            </div>

            <footer style={styles.footer}>
                <div style={styles.footerContainer}>
                    <p>© 2024 Suplex Asia PVT (LTD). All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
}

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        background: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)', // Gradient from light teal to dark teal
        //minHeight: '100vh', // Ensure the container covers the full viewport height
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Distribute content evenly
        alignItems: 'center', // Center horizontally
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        paddingBottom: '50px', // Space for any footer if needed
        width: '1620px', // Fixed width to enforce desktop view
        overflowX: 'hidden', // Prevent horizontal scrollbars
      },
      title: {
          backgroundColor: 'transparent', // Removed background color
          color: '#008080', // Dark teal for text
          fontSize: '1.5em', // Increased font size
          fontWeight: 'bold',
        },
      nav: {
        display: 'flex',
        justifyContent: 'space-between', // Space out the title and button
        alignItems: 'center',
        backgroundColor: '#FFFAFA', // White color for the nav bar
        height:'120px',
        color: 'black',
        //padding: '0.5em', // Reduced padding
        width: '100%',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      },
      navList: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        display: 'flex',
        alignItems: 'center', // Align items to the center vertically
      },
      navItem: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '1em', // Adjust margin for spacing
      },
      logo: {
        height: '80px', // Increased height of the logo
        marginRight: '10px',
      },
      navText: {
        backgroundColor: 'transparent', // Removed background color
        color: '#008080', // Dark teal for text
        fontSize: '1.5em', // Default font size
        fontWeight: 'bold',
        // Media query for tablets and small devices
        '@media (max-width: 768px)': {
          fontSize: '1.2em', // Smaller font size for tablets
        },
        // Media query for mobile devices
        '@media (max-width: 480px)': {
          fontSize: '0.1em', // Smaller font size for mobile phones
        },
      },
      Bwrapper: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'relative', // Position relative to contain absolutely positioned children
        minHeight: '100vh', // Ensure wrapper covers the full viewport height
        background: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)', // Gradient from light teal to dark teal
    },
    taskBar: {
      display: 'flex',
      justifyContent: 'center', // Center items horizontally
      alignItems: 'center', // Center items vertically
      margin: '1em 0',
      backgroundColor: '#008080', // Teal color for the task bar
      padding: '0.5em', // Reduced padding
      borderRadius: '8px',
      flexWrap: 'wrap', // Allow items to wrap on smaller screens
      position: 'relative', // Ensure positioning context for children
    },
    
    menuContainer: {
      position: 'absolute', // Position relative to taskBar
      left: '1em', // Add space from the left edge of taskBar
      top: '50%', // Center vertically
      transform: 'translateY(-50%)', // Center vertically
      zIndex: 1, // Ensure it is above other elements
      display: 'flex', // Ensure menuButton is centered
      alignItems: 'center',
      justifyContent: 'center',
    },
    
    menuButton: {
      backgroundColor: '#008080', // Teal color
      color: '#E0FFFF', // Light teal text
      fontWeight: 'bold',
      border: 'none', // Removed border
      borderRadius: '5px',
      cursor: 'pointer',
      padding: '0.6em 0.6em', // Adjusted padding
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      position: 'relative',
      margin: '0 1em', // Add space on left and right sides
    },
    
    menuLine: {
      width: '20px', // Line width
      height: '2px', // Line height
      backgroundColor: '#E0FFFF', // Light teal color
      margin: '2px 0',
    },
    
    menuDropdown: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      backgroundColor: '#E0FFFF', // Light teal color
      border: 'none', // Removed border
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      zIndex: 1,
      minWidth: '100px',
      top: '100%',
      left: '0',
      padding: '0.5em',
    },
    
    menuDropdownItem: {
      padding: '0.3em 0.6em', // Padding
      backgroundColor: '#E0FFFF', // Light teal color
      color: '#008080', // Dark teal text
      fontWeight: 'bold',
      border: 'none', // Removed border
      cursor: 'pointer',
      borderRadius: '6px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      margin: '0.25em 0', // Margin
      textAlign: 'center',
      fontSize: '0.9em', // Font size
    },
    
      button: {
        padding: '0.5em 1.5em', // Reduced padding
        backgroundColor: '#E0FFFF', // Very light teal
        color: '#008080', // Dark teal text
        fontWeight: 'bold',
        border: 'none', // Removed border
        cursor: 'pointer',
        margin: '0.5em',
        borderRadius: '5px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        minWidth: '50px', // Reduced min width
        fontSize: '0.9em', // Reduced font size
        textAlign: 'center',
      },
      dropdownContainer: {
        position: 'relative',
        display: 'inline-block',
      },
      dropdown: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        backgroundColor: '#E0FFFF', // Very light teal
        border: 'none', // Removed border
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        zIndex: 1,
        minWidth: '100px',
        top: '100%',
        left: 0,
        padding: '0.5em',
      },
      dropdownItem: {
        padding: '0.3em 1.5em', // Reduced padding
        backgroundColor: '#E0FFFF', // Very light teal
        color: '#008080', // Dark teal text
        fontWeight: 'bold',
        border: 'none', // Removed border
        cursor: 'pointer',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        margin: '0.5em 0',
        textAlign: 'center',
        fontSize: '0.9em', // Reduced font size
      },
    imageSection: {
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
        height: '250px',
    },
    imageWrapper: {
        display: 'flex',
        animation: 'scroll 10s linear infinite',
        height: '100%',
    },
    image: {
        flexShrink: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        margin: '0 10px',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    footer: {
        backgroundColor: '#008080', // Teal color
        color: 'white',
        textAlign: 'center',
        padding: '1em',
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
    },
    footerContainer: {
        maxWidth: '80%',
        margin: '0 auto',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '600px', // Adjusted maxWidth for practicality
        margin: '40px', // Centered with top and bottom margins
        backgroundColor: '#f5f5f5',
        padding: '50px', // Reasonable padding for inner content
        borderRadius: '12px', // Rounded corners
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)', // Shadow for depth
        //boxSizing: 'border-box',
        //position: 'relative', // Ensure form positioning can be controlled
        top: '5px', // Move the form up by 5px
        
    },
    allowbutton: {
            padding: '0.5em 1.5em', // Reduced padding
            backgroundColor: '#E0FFFF', // Very light teal
            color: '#008080', // Dark teal text
            fontWeight: 'bold',
            border: 'none', // Removed border
            cursor: 'pointer',
            margin: '0.5em',
            borderRadius: '5px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            minWidth: '50px', // Reduced min width
            fontSize: '0.7em', // Reduced font size
            textAlign: 'center',
          },
    header: {
        fontSize: '1.8em',
        marginBottom: '20px',
        color: '#008080',
    },
    text: {
        fontSize: '1em',
        color: '#333',
        lineHeight: '1.6',
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '20px',
    },
    checkbox: {
        marginRight: '10px',
    },
    label: {
        fontSize: '1em',
        color: '#333',
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
    }
};

export default TermsAndConditions;
